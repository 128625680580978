<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 7.12 -->
                <div class="row justify-content-center" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">12. Worden zorgverleners getraind met betrekking tot tillen/transfers?</h3>
                        <!-- Question 7.12a -->
                        <h4>12a. Worden zorgverleners, voordat ze uitvoerend actief worden in uw organisatie, getraind in manuele verplaatsingstechnieken? </h4>
                        <p class="mb-5">Alle zorgverleners moeten voorbereid zijn op het werk door het volgen van een basisinstructie til- en transfertechnieken. Dit moet minimaal twee dagdelen in beslag nemen. Zorgverleners moeten deze
                            training in hun basisopleiding hebben
                            gekregen. Het is desondanks verstandig de inhoud en kwaliteit van de training en hun vaardigheid te checken.</p>
                        <ChoiceField id="12.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('12.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Ter ondersteuning van de training in manuele verplaatsingstechnieken staat er op <a href="https://www.goedgebruik.nl"
                                                                                                                                                target="_blank">GoedGebruik</a> een
                                            groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier,
                                            is
                                            te vinden in het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a
                                                href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor elke
                                            zorgverlener.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.12b -->
                        <h4>12b. Worden leerlingen, voordat ze uitvoerend actief worden in uw organisatie, getraind in manuele verplaatsingstechnieken?</h4>
                        <p class="mb-5">Alle leerlingen moeten voorbereid zijn op het werk door het volgen van een basisinstructie til- en transfertechnieken. Dit moet minimaal twee dagdelen in beslag nemen. Leerlingen moeten deze training
                            in hun basisopleiding krijgen, maar het is
                            desondanks verstandig de inhoud en kwaliteit van de training te checken. </p>
                        <ChoiceField id="12.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('12.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.b').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Ter ondersteuning van de training in manuele verplaatsingstechnieken staat er op <a href="https://www.goedgebruik.nl"
                                                                                                                                                target="_blank">GoedGebruik</a> een
                                            groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier,
                                            is
                                            te vinden in het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a
                                                href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor elke
                                            zorgverlener
                                            (in opleiding). </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.12c -->
                        <h4>12c. Worden zorgverleners, voordat ze uitvoerend actief worden in uw organisatie, getraind in het werken met tilhulpmiddelen?</h4>
                        <p class="mb-5">Alle zorgverleners moeten voorbereid zijn op het werk door het volgen van een basisinstructie in het werken met tilhulpmiddelen (tilliften,
                            glijzeilen, etc.). Dit moet minimaal twee dagdelen in beslag nemen. Zorgverleners moeten deze training
                            in hun basisopleiding krijgen, maar het is desondanks verstandig de inhoud en kwaliteit van de training te checken.</p>
                        <ChoiceField id="12.c" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('12.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.c').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                            een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                            in
                                            het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                 target="_blank">De Leerstand</a>
                                            voor
                                            een individueel ‘op maat’ leeradvies voor elke zorgverlener.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.12d -->
                        <h4>12d. Worden leerlingen, voordat ze uitvoerend actief worden in uw organisatie, getraind in het gebruik van tilhulpmiddelen?</h4>
                        <p class="mb-5">Alle leerlingen moeten voorbereid zijn op het werk door het volgen van een basisinstructie in het werken met tilhulpmiddelen (tilliften,
                            glijzeilen, etc.). Dit moet minimaal twee dagdelen in beslag nemen. Leerlingen moeten deze training in
                            hun basisopleiding krijgen, maar het is desondanks verstandig de inhoud en kwaliteit van de training te checken.</p>
                        <ChoiceField id="12.d" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('12.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                            een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                            in
                                            het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                 target="_blank">De Leerstand</a>
                                            voor
                                            een een individueel ‘op maat’ leeradvies voor elke zorgverlener (in opleiding).
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.12e -->
                        <h4>12e. Worden alle zorgverleners regelmatig getraind in het uitvoeren van manuele tiltechnieken?</h4>
                        <p class="mb-5">Zoals afgesproken in de Arbocatalogi is er, gecombineerd met een training in het gebruik van hulpmiddelen, standaard één keer per jaar
                            minimaal één dagdeel bijscholing nodig in manuele transfertechnieken voor medewerkers die zorg verlenen aan cliënten in de
                            Mobiliteitsklassen B-E. Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per
                            twee jaar). Een langere periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven,
                            dan minder vaak een langere. In deze training kan ook aandacht worden besteed aan het voorkomen van en het omgaan met agressie en weerstand
                            tijdens de basiszorg.</p>
                        <ChoiceField id="12.e" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('12.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.e').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                            een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                            in
                                            het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                 target="_blank">De Leerstand</a>voor
                                            een individueel ‘op maat’ leeradvies voor elke zorgverlener.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.12f -->
                        <h4>12f. Worden alle zorgverleners regelmatig getraind in het gebruik van tilhulpmiddelen?</h4>
                        <p class="mb-5">Er is, gecombineerd met een training in manuele transfertechnieken, standaard één keer per jaar minimaal één dagdeel bijscholing nodig in het gebruik van tilhulpmiddelen voor medewerkers die zorg
                            verlenen aan cliënten in de Mobiliteitsklassen B-E. Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per
                            twee jaar). Een langere periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven,
                            dan minder vaak een lagere. In deze training kan ook aandacht worden besteed aan het voorkomen van en het omgaan met agressie en weerstand
                            tijdens de basiszorg.</p>
                        <ChoiceField id="12.f" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('12.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.f').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                            een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                            in
                                            het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                 target="_blank">De Leerstand</a>voor
                                            een individueel ‘op maat’ leeradvies voor elke zorgverlener.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.13 -->
                        <h3>13. Worden medewerkers van ondersteunende diensten die fysiek zwaar werk doen voorgelicht en getraind?</h3>
                        <!-- Question 7.13a -->
                        <h4>13a. Worden deze medewerkers, voordat ze uitvoerend actief worden voorgelicht, getraind in tiltechnieken en werkmethoden?</h4>
                        <p class="mb-5">Alle medewerkers van ondersteunende diensten die fysiek belastend werk doen moeten voorbereid zijn op het werk door het volgen van een
                            basisinstructie tiltechnieken en werkmethoden. Deze training moet minimaal twee dagdelen in beslag nemen.</p>
                        <ChoiceField id="13.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('13.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub text-justify">
                                            Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                            de
                                            Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                          target="_blank">LOCOmotion
                                            winkeltje</a>.
                                            Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                            manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                            Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                            medewerkers
                                            van ondersteunende diensten.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.13b -->
                        <h4>13b. Worden deze medewerkers, voordat ze uitvoerend actief worden, getraind in het gebruik van tilhulpmiddelen en werkmaterialen?</h4>
                        <p class="mb-5">Alle medewerkers van ondersteunende diensten die fysiek belastend werk doen moeten voorbereid zijn op het werk door het volgen van een
                            basisinstructie in het gebruik van tilhulpmiddelen en werkmaterialen. Deze training moet minimaal twee dagdelen in beslag nemen.</p>
                        <ChoiceField id="13.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('13.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.b').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub text-justify">
                                            Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                            de
                                            Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                          target="_blank">LOCOmotion
                                            winkeltje</a>.
                                            Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                            manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                            Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                            medewerkers
                                            van ondersteunende diensten.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.13c -->
                        <h4>13c. Worden deze medewerkers jaarlijks voorgelicht en getraind in het uitvoeren van tiltechnieken en werkmethoden?</h4>
                        <p class="mb-5">Er is, gecombineerd met een training in het gebruik van tilhulpmiddelen en werkmaterialen, standaard één keer per jaar minimaal één dagdeel bijscholing nodig in tiltechnieken en werkmethoden voor
                            medewerkers van ondersteunende diensten die fysiek zwaar werk doen.<br>
                            Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per twee jaar). Een langere
                            periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven, dan minder vaak een
                            langere.</p>
                        <ChoiceField id="13.c" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('13.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.c').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub text-justify">
                                            Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                            de
                                            Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                          target="_blank">LOCOmotion
                                            winkeltje</a>.
                                            Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                            manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                            Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                            medewerkers
                                            van ondersteunende diensten.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <!-- Question 7.13d -->
                        <h4>13d. Worden deze medewerkers jaarlijks getraind in het gebruik van tilhulpmiddelen en werkmaterialen?</h4>
                        <p class="mb-5">Er is, gecombineerd met een training in tiltechnieken en werkmethoden, standaard één keer per jaar minimaal één dagdeel bijscholing nodig in het gebruik van tilhulpmiddelen en werkmaterialen voor
                            medewerkers van ondersteunende diensten die fysiek zwaar werk doen.<br/>
                            Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per twee jaar). Een langere
                            periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven, dan minder vaak een
                            langere.</p>
                        <ChoiceField id="13.d" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Te beperkt',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('13.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub text-justify">
                                            Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                            de
                                            Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                          target="_blank">LOCOmotion
                                            winkeltje</a>.
                                            Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                            manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                            Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                            medewerkers
                                            van ondersteunende diensten.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-8'}" class="btn btn-grad mt-3">8. Hulpmiddelen<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress/>
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step7',
    components: {ChoiceField, Progress}
}
</script>
