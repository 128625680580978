<template>
    <div>
        <section class="fullscreen-85 bg-circles pb-0 pt-xs-0">
            <div class="container v-align-transform">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-6 col-12" data-aos="fade-right">
                        <router-link :to="{name: 'physical-home'}" class="navbar-brand mb-5"><img src="/img/logo-fb.svg" class="logo"></router-link>
                        <h1 class="h3 mb-3">Is het beleid fysieke belasting in uw organisatie op orde?</h1>
                        <p>De BeleidsSpiegel is een checklist die u helpt om het beleid fysieke belasting in uw organisatie in kaart te brengen en te ‘spiegelen’ aan de landelijke
                           afspraken zoals ze zijn vastgelegd in de arbocatalogus van uw branche. In het ideale geval kunt u op alle vragen ‘ja’ antwoorden. Zo niet, dan geeft deze
                           online BeleidsSpiegel u tips over hoe u de situatie kunt verbeteren.</p>
                        <div class="btn-container">
                            <router-link :to="{name: 'physical-home'}" class="btn btn-grad">Beleidsspiegel Fysieke Belasting</router-link>
                        </div>
                    </div>
                    <div class="col-lg-5 ml-auto col-md-6 col-12" data-aos="fade-right">
                        <router-link :to="{name: 'pyschosocial-home'}" class="navbar-brand mb-5"><img src="/img/logo-pa.svg" class="logo"></router-link>
                        <h1 class="h3 mb-3">Is het beleid psychosociale arbeidsbelasting in uw organisatie op orde?</h1>
                        <p>
                            De BeleidsSpiegel PSA (psychosociale arbeidsbelasting) is een checklist die u helpt om het beleid psychosociale arbeidsbelasting in uw organisatie in kaart te brengen en te
                            'spiegelen' aan landelijke afspraken. In het ideale geval kunt u op alle vragen ‘ja’ antwoorden. Zo niet, dan geeft deze online BeleidsSpiegel u tips over hoe u de situatie
                            kunt verbeteren.
                        </p>
                        <div class="btn-container">
                            <router-link :to="{name: 'psychosocial-home'}" class="btn btn-grad-green">Beleidsspiegel Psychosociale Arbeidsbelasting</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Splashscreen',
}
</script>