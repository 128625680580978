<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 4.9 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">4. Psychosociale arbeidsbelasting en de subthema's</h3>
                        <p class="mb-5">
                            Hiervoor zijn we stap voor stap de algemene structuur van PSA beleid langsgelopen. Dit wordt nu gespecificeerd in subthema’s. De Arbo-risico’s op het gebied van PSA worden
                            namelijk meestal onderverdeeld in de volgende categorieën: discriminatie, seksuele intimidatie, agressie en geweld, pesten en tenslotte werkdruk. We volgen nu deze
                            indeling, al is overlap onontkoombaar.
                        </p>
                        <h4>4a. Discriminatie</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Discriminatie op de werkvloer komt op allerlei manieren voor. Bijvoorbeeld al bij de werving van werknemers kunnen naam of pasfoto een grote rol spelen.
                                    Discriminatie
                                    kan bewust of onbewust zijn, maar is in alle gevallen ongeoorloofd.
                                    <a href="https://www.nursing.nl/blog/gastblog-zoe-stop-racisme-in-de-zorg/" target="_blank">Lees hier</a>
                                    bijvoorbeeld de gastblog van zorgverlener Zoë in Nursing. Zorgverleners èn ook zorgvragers zouden zich nooit hoeven af te vragen of zij anders behandeld worden,
                                    omwille van hun huidskleur, hun achtergrond of achternaam. Als een zorgvrager zogenaamde grapjes maakt of zich kwetsend uitlaat over een zorgverlener of
                                    zorgverleners is het van groot belang niet stil te blijven, maar deze vorm van discriminatie of racisme te benoemen. Acceptatie en steun zijn dan cruciaal.
                                    Werkgevers hebben een belangrijke
                                    rol bij het voorkomen van discriminatie op de werkvloer.
                                </p>
                            </div>
                        </div>
                        <h4>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van discriminatie op de werkplek?</h4>
                        <ChoiceField id="4.a.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="4.a.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('4.a.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.a.a').value !== null) || (this.$store.getters['psychosocialStore/getQuestionById']('4.a.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.a.b').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            De Inspectie SZW heeft samen met TNO een checklist ontwikkeld waarmee werkgevers de risico’s op discriminatie in hun organisatie in kaart kunnen brengen.
                                            Daarnaast geeft de checklist ook tips en suggesties over hoe discriminatie voorkomen kan worden. <a href="/uploads/wegwijzerongewenstgedrag-tno-en-discrimnatie-werkplek.pdf" target="_blank">Je download de checklist hier</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4 class="mt-5">4b. Seksuele intimidatie</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Seksuele intimidatie kan verschillende vormen aannemen, waaronder de onderstaande drie, maar nadrukkelijk ook combinaties daarvan zijn mogelijk (zie
                                    <a href="https://arboportaal.nl" target="_blank">arboportaal.nl</a>).
                                </p>
                                <ul>
                                    <li>Woorden (verbale intimidatie): Denk hier aan seksueel getinte opmerkingen, grappen, intieme vragen stellen of toespelingen;</li>
                                    <li>Gebaren, gezichtsuitdrukking (non-verbaal): Bijvoorbeeld staren, seksueel gerichte gebaren, of het tonen van seksueel getinte of pornografische afbeeldingen
                                        (ook
                                        via
                                        mail, WhatsApp of sms);
                                    </li>
                                    <li>Direct lichamelijk contact (fysiek): Hieronder worden alle vormen van ongewenst lichamelijk contact verstaan. Dit kan een arm om de schouder zijn, beetpakken,
                                        knijpen,
                                        zoenen, de weg versperren, aanranden of zelfs verkrachten. In iemands ‘comfortzone’ komen kan ook een vorm van fysieke seksuele intimidatie zijn.
                                    </li>
                                </ul>
                                <p class="mb-0">
                                    Seksuele intimidatie kan opzettelijk plaatsvinden, maar ook onbedoeld. Dit maakt het soms een lastig te herkennen probleem. Het gaat om hoe de ontvanger het
                                    ervaart: wat voor de één onschuldig is, kan voor de ander als vervelend, ongewenst, of intimiderend ervaren worden. Dat laatste is dan bepalend.
                                </p>
                            </div>
                        </div>
                        <h4>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van seksuele intimidatie op de werkplek?</h4>
                        <ChoiceField id="4.b.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="4.b.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('4.b.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.b.a').value !== null) || (this.$store.getters['psychosocialStore/getQuestionById']('4.b.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.b.b').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <ol class="sub text-justify">
                                            <li>
                                                <a href="https://www.arbocatalogusvvt.nl/thema/ongewenst-gedrag-collegas-leiding/seksuele-intimidatie/algemeen/" target="_blank">https://www.arbocatalogusvvt.nl/thema/ongewenst-gedrag-collegas-leiding/seksuele-intimidatie/algemeen</a><br><br>
                                            </li>
                                            <li>
                                                Er is een overzichtelijke ‘wegwijzer seksuele intimidatie’ gemaakt die u <a href="/uploads/WegwijzerSeksueleIntimidatie.pdf" target="_blank">hier kunt
                                                                                                                                                                                             downloaden</a>.<br><br>
                                            </li>
                                            <li>
                                                Gebruik vooral ook het <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/wat-is" target="_blank">Arboportaal</a> voor dit onderwerp.
                                                Het bevat meer gedetailleerde informatie en tips.<br><br>
                                            </li>
                                            <li>
                                                Wat zegt de wet over seksuele intimidatie?<br>
                                                <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/wet " target="_blank">https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/wet</a><br><br>
                                            </li>
                                            <li>
                                                Maatregelen tegen seksuele intimidatie op het werk<br>
                                                <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/maatregelen" target="_blank">https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/maatregelen</a><br><br>
                                            </li>
                                            <li>
                                                Handige tools en instrumenten<br>
                                                <a href="https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/handige-tools-en-instrumenten" target="_blank">https://www.arboportaal.nl/onderwerpen/seksuele-intimidatie/handige-tools-en-instrumenten</a><br><br>
                                            </li>
                                            <li>
                                                Free Learning module over intimiteit en seksualiteit in het verpleeghuis<br>
                                                <a href="https://www.free-learning.nl/modules/intimiteit-en-seksualiteit-in-het-verpleeghuis/start.html" target="_blank">https://www.free-learning.nl/modules/intimiteit-en-seksualiteit-in-het-verpleeghuis/start.html</a>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4 class="mt-5">4c Agressie en geweld</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Agressie door cliënten, familie, bezoek of anderen waaronder ook collega’s, kan het risico op het krijgen van klachten en uitval verhogen. Werkgevers zijn op grond
                                    van deze wet verplicht om een beleid te voeren dat erop gericht is om deze vorm van arbeidsbelasting te voorkomen of te beperken. Het Arbo-besluit werkt deze
                                    verplichting in afdeling 4, artikel 2.15 verder uit. Er moeten in elk geval maatregelen ter voorkoming van agressie op de werkvloer worden opgenomen in een plan van
                                    aanpak. Werknemers moeten aantoonbaar voorgelicht zijn over de risico’s en de maatregelen die de organisatie heeft getroffen.
                                </p>
                                <p>
                                    Maar in dit geval beschermt ook het Burgerlijk Wetboek werknemers meer impliciet tegen agressie. Op grond van artikel 7:658 heeft de werkgever een zorgplicht voor
                                    de veiligheid van de werkomgeving.
                                    Als een werknemer slachtoffer wordt op grond van zijn godsdienst, levensovertuiging, politieke gezindheid, ras, geslacht, nationaliteit, hetero- of homoseksuele
                                    geaardheid of burgerlijke staat, dan wordt hij ook beschermd door de Algemene wet gelijke behandeling. Werknemers kunnen in dit geval ook een klacht indienen bij
                                    het College voor de Rechten van de Mens.
                                </p>
                                <p>
                                    In het geval van fysiek geweld valt dit onder het Wetboek van Strafrecht, want dit is een strafbaar feit. Het is daarom nodig om bij fysiek geweld aangifte van te
                                    doen bij de politie.
                                </p>
                                <p>
                                    Bron: <a href="https://arboportaal.nl" target="_blank">arboportaal.nl</a>
                                </p>
                            </div>
                        </div>
                        <h4>
                            Is er in uw organisatie beleid op schrift aanwezig ten aanzien van agressie en geweld op de werkplek?
                        </h4>
                        <h4>Voor het omgaan met agressieve cliënten?</h4>
                        <ChoiceField id="4.c.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="4.c.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />

                        <h4 class="mt-3">Voor het omgaan met agressieve bezoekers of anderen?</h4>
                        <ChoiceField id="4.c.c" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="4.c.d" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('4.c.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.a').value !== null) ||
                                    (this.$store.getters['psychosocialStore/getQuestionById']('4.c.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.b').value !== null) ||
                                    (this.$store.getters['psychosocialStore/getQuestionById']('4.c.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.c').value !== null) ||
                                    (this.$store.getters['psychosocialStore/getQuestionById']('4.c.d').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.c.d').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <ol class="mt-0 sub text-justify">
                                            <li>
                                                Op <a href="https://www.blijfinzetbaar.nl/" target="_blank">Blijf Inzetbaar</a> vindt u relevant en praktisch materiaal om agressie in de basiszorg te
                                                voorkomen. Kijk ook op Free Learning www.free-learning.nl voor e-learning modules over het voorkomen en omgaan met agressie.
                                            </li>
                                            <li>
                                                Kijk ook op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> voor e-learning modules over het voorkomen en omgaan met agressie.
                                                <ul>
                                                    <li><a href="https://www.free-learning.nl/modules/omgaan-met-agressie/start" target="_blank">Omgaan met agressie</a></li>
                                                    <li><a href="https://www.free-learning.nl/modules/probleemgedrag/start" target="_blank">Probleemgedrag I</a></li>
                                                    <li><a href="https://www.free-learning.nl/modules/probleemgedragII/start" target="_blank">Probleemgedrag II</a></li>
                                                </ul>
                                                <br>
                                            </li>
                                            <li>
                                                De Inspectie SZW heeft een korte brochure gemaakt met de punten waar zij op letten bij inspecties op dit arborisico: De aanpak van agressie en geweld
                                                Waar let de Inspectie SZW op? Deze kunt u <a href="/uploads/Aanpak-agressie-en-geweld-waar-let-deInspectie-SZW-op.pdf" target="_blank">hier
                                                                                                                                                                                       downloaden</a>.
                                            </li>
                                            <li>De al genoemde website Arboportaal.nl bevat erg veel informatie over deze aspecten.</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4 class="mt-5">4d. Pesten</h4>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Er is sprake van pesten als er herhaald ongewenst negatief gedrag is waartegen iemand niet in staat is zich te verdedigen. Pesten is soms lastig te definiëren en er
                                    is soms een geleidelijke overgang naar agressie en geweld.
                                </p>
                                <p>
                                    Voor wat betreft ‘pesten’ is er qua formele eisen aan het organisatiebeleid een sterke overlap met agressie en geweld (zie aldaar). Ook voor pesten geldt dat er
                                    aantoonbaar beleid moet zijn en dat werknemers voorgelicht moeten zijn.
                                </p>
                                <p class="mb-0">
                                    Pesten op het werk is een serieus probleem. Meer dan een half miljoen mensen zeggen wel eens gepest te worden op het werk. De grens van wat pesten is en wanneer het
                                    meer gaat om ‘grapjes’ is soms lastig te bepalen. En ook iets als negeren en uitsluiten valt onder ‘pesten’. De perceptie van de ontvanger is dan bepalend.
                                    <a href="/uploads/factsheet pesten.jpg" target="_blank">Er is een factsheet gemaakt over pesten als probleem</a>.
                                </p>
                            </div>
                        </div>
                        <h4>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van pesten op de werkplek?</h4>
                        <ChoiceField id="4.d.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="4.d.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('4.d.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.d.a').value !== null) ||
                                    (this.$store.getters['psychosocialStore/getQuestionById']('4.d.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.d.b').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            Er is een Wegwijzer Pesten gemaakt met waardevolle tips en adviezen. <a href="/uploads/WegwijzerPesten.pdf" target="_blank">Deze kunt u hier downloaden</a>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4 class="mt-5">4e. Werkdruk en duurzame inzetbaarheid</h4>
                        <p>
                            Werkdruk en duurzame inzetbaarheid zijn beide enorm brede begrippen en er is erg veel verschenen over deze onderwerpen en wat er aan gedaan kan worden. In <a
                            href="https://youtu.be/Nh5WX5oZgaw" target="_blank">dit filmpje</a> wordt die breedte goed weergegeven.
                        </p>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Werkdruk en zeker beleving van werkdruk wordt meestal ook onder PSA geschaard. Werkdruk is een goede voorspeller voor duurzame inzetbaarheid, verzuim, burn-out en
                                    vervroegd uittreden uit het beroep van zorgverlener. En, andersom, er zijn aanwijzingen voor een direct verband tussen werkdruk en indicatoren voor de kwaliteit van
                                    zorg, uitkomstmaten voor de patiënt en de door zorgverleners gerapporteerde kwaliteit van zorg.
                                </p>
                                <p>
                                    Werkdruk ontstaat als een werknemer structureel niet kan voldoen aan de eisen die het werk aan hem stelt. Bijvoorbeeld wanneer een werknemer vaak of voortdurend
                                    onvoldoende tijd heeft om zijn werk af te krijgen of onmogelijk het gewenste resultaat kan halen. Zeker wanneer een werknemer hier zelf niets aan kan veranderen, is
                                    er
                                    al snel sprake van te hoge werkdruk. Werknemers die langdurig te maken hebben met te hoge werkdruk raken niet alleen gestrest, maar veelal ook gedemotiveerd. Als
                                    zij
                                    uitvallen, wordt de stap terug naar werk daardoor extra moeilijk.
                                </p>
                                <p class="mb-0">
                                    Het ontbreekt aan een gouden standaard voor de aanpak van werkdruk, maar cruciaal is dat er maatwerk nodig is en dat u actief beleid voert. De criteria daarvoor
                                    zijn
                                    echter weinig vastomlijnd en hangen ook sterk af van de situatie in uw organisatie. In een thuiszorgorganisatie ziet dat er heel anders uit dan in bijvoorbeeld op
                                    een
                                    Spoed Eisende Hulp. We geven een aantal handvatten en verwijzen naar de website <a href="https://www.arboportaal.nl/onderwerpen/werkdruk" target="_blank">arboportaal.nl</a> en <a
                                    href="https://gezondenzeker.nl" target="_blank">gezondenzeker.nl</a>.
                                </p>
                            </div>
                        </div>
                        <h4>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van werkdruk?</h4>
                        <ChoiceField id="4.e.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="4.e.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('4.e.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.e.a').value !== null) ||
                                    (this.$store.getters['psychosocialStore/getQuestionById']('4.e.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('4.e.b').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <ol class="text-justify sub">
                                            <li>
                                                <strong>Basisinspectiemodule (BIM) Werkdruk</strong><br>
                                                Dit is het <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2017/12/KoersKaart-DI.pdf" target="_blank">document</a> dat de Inspectie SZW gebruikt voor haar inspectiebeleid.<br><br>
                                            </li>
                                            <li>
                                                <strong>Zelfinspectie Werkdruk</strong><br>
                                                Doet u voldoende tegen te hoge werkdruk? Gebruik de zelfinspectietool Werkdruk en Ongewenst gedrag. Ga naar <a
                                                href="https://www.zelfinspectie.nl/zelfinspectie/werkdruk-en-ongewenst-gedrag/te-hoge-werkdruk" target="_blank">werkdrukenongewenstgedrag.zelfinspectie.nl</a>.<br><br>
                                            </li>
                                            <li>
                                                <strong>BalansRadar</strong><br>
                                                Van den Oetelaar et al. (2016) onderzocht manieren om de werkdruk van verpleegkundigen te managen en de invloed van de patiënt daarop te kunnen
                                                reguleren. Diverse complexe systemen blijken niet voldoende in staat om dit te kunnen en/of blijken een te grote administratie en registratielast voor
                                                zorgverleners met zich mee te brengen: het is en blijft dus lastig de bezetting optimaal te laten zijn. Ook Myny et al. voerden zeer gedetailleerd
                                                onderzoek uit. Zij destilleerden belangrijke patiënt-gebonden factoren: 1. het aantal werkonderbrekingen, 2. het verloop onder de patiëntengroep en 3.
                                                het aantal uit te voeren registraties (registratielast).<br><br>
                                                Om hier rekening mee te houden in de zorg werd de BalansRadar ontwikkeld. De BalansRadar bouwt voort op deze onderzoeken en helpt bij het leveren van
                                                die fine-tuning, verfijning, onderbouwing en bijsturing om alert te reageren op discrepanties tussen personele bezetting, (ervaren) werkdruk en
                                                wijzigingen in de werkdruk. Dat zou dan moeten leiden tot aanpassing in de toewijzing van formatie of tot andersoortige maatregelen (zoals meer
                                                hulpmiddelen of een andere methode van wondverzorgen etc.).
                                                In de linkjes hieronder vindt u de instrumenten.
                                                <ul>
                                                    <li><a href="/uploads/Stappenplan-BR.docx" target="_blank">Stappenplan BalansRadar</a></li>
                                                    <li><a href="/uploads/Werkdruk-meten-De-BalansRadar.pdf" target="_blank">Werkdruk meten, De BalansRadar</a></li>
                                                    <li><a href="/uploads/Toelichting-vragen.doc" target="_blank">Toelichting vragen</a></li>
                                                    <li><a href="/uploads/BR-Individu.xlsx" target="_blank">Excel BalansRadar Individu</a></li>
                                                    <li><a href="/uploads/BR-groep.xlsx" target="_blank">Excel BalansRadar Groep</a></li>
                                                </ul>
                                                <br><br>
                                            </li>
                                            <li>
                                                <strong>Koerskaart Duurzame Inzetbaarheid</strong><br>
                                                <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2017/12/KoersKaart-DI.pdf" target="_blank">Deze praktische koerskaart</a>
                                                maakt het mogelijk om op individuele basis het gesprek aan te gaan of het bewustzijn over de noodzaak van duurzame inzetbaarheid te vergroten.<br><br>
                                            </li>
                                            <li>
                                                <strong>Platform Duurzame Inzetbaarheid</strong><br>
                                                <a href="https://www.duurzameinzetbaarheid.nl/" target="_blank">Dit platform</a> geeft veel informatie over alles op dit themagebied.<br><br>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-5'}" class="btn btn-grad mt-3">5.Training en instructie <i
                            class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep4',
    components: { ChoiceField, Progress }
}
</script>
