<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 3.8 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">3. Het veiligheidsklimaat in uw organisatie</h3>
                        <p class="mb-5">Een goed veiligheidsklimaat, oftewel een gezond en veilig organisatie-klimaat is een belangrijke pilaar van PSA-beleid. De volgende 5 onderdelen zijn daarvoor
                                        in elk geval van belang (Bronkhorst, 2018). Geef nu uzelf een rapportcijfer voor elk van de stellingen. Een 1 betekent dat u het totaal oneens bent met de
                                        stelling. Een 10 is betekent dat u het er geheel mee eens bent.</p>
                        <div class="row justify-content-center mb-5">
                            <NumberField label="A. <strong>Hoger management legt prioriteit bij gezond en veilig werken</strong>" id="3.a" />
                            <NumberField label="B. <strong>Direct leidinggevenden zijn betrokken bij PSA beleid</strong>" id="3.b" />
                            <NumberField label="C. <strong>De groepsnorm bij medewerkers over PSA beleid is positief</strong>" id="3.c" />
                            <NumberField label="D. <strong>De communicatie over ons PSA beleid is goed</strong>" id="3.d" />
                            <NumberField label="E. <strong>Werknemers zijn betrokken bij PSA beleid</strong>" id="3.e" />
                        </div>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Bronkhorst promoveerde op onderzoek naar de kenmerken van een gezond en veilig klimaat in de zorg en het behoud van personeel. Het bleek dat zorgverleners zich
                                    lichamelijk
                                    en geestelijk beter voelen in organisaties die beter scoren op een gezond en veilig klimaat. Deze zorgorganisaties hebben een lager ziekteverzuim en hun medewerkers
                                    gebruiken zelf minder zorg. De verbetering van het organisatieklimaat blijkt een belangrijk aangrijpingspunt om gezonder en veiliger te werken in de zorg.
                                    Investeren in een
                                    dergelijk organisatieklimaat zal in de nabije toekomst steeds belangrijker worden om ook op de lange termijn in ons land de zorg te kunnen leveren die nodig is.
                                    Het proefschrift kunt u <a href="/uploads/Proefschrift-Babette-Bronkhorst-Healthy-Workplaces-in-Health-Care.pdf" target="_blank">hier downloaden</a>.
                                </p>
                            </div>
                        </div>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">
                                            Gebruik deze scores om speerpunten in uw plan van aanpak op te stellen om zo effectief mogelijk uw beleid te voeren.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-4'}" class="btn btn-grad mt-3">4. Psychosociale arbeidsbelasting en de subthema's <i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import NumberField from "@/components/steps/includes/NumberField";

export default {
    name:       'PsychosocialStep3',
    components: { NumberField, Progress }
}
</script>
