<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 3.8 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">3. Verankering deskundigheid fysieke belasting.</h3>
                        <h4>8a. Is er een platform, commissie of werkgroep die zich bezighoudt met het beleid fysieke belasting?</h4>
                        <p class="mb-5">Om het beleid fysieke belasting voortdurend te kunnen bijsturen is communicatie tussen management/directie, beleidsondersteuners, eventuele ErgoCoaches en uitvoerenden essentieel. Het ligt voor de
                            hand daarvoor een werkgroep of commissie in het leven te roepen. Naarmate het beleid beter is geïmplementeerd kan deze groep wat meer op de achtergrond actief worden en vooral beschikbaar zijn als vraagbaak bij
                            probleemsituaties en actief ingrijpen als er sprake is van risicovolle situaties.</p>
                        <ChoiceField id="8.a"/>
                        <h4>8b. Zijn er direct op de werkvloer ErgoCoaches actief?</h4>
                        <p class="mb-5">ErgoCoaches zijn 'gewone' medewerkers die een extra verantwoordelijkheid en deskundigheid hebben op het gebied van fysieke belasting. Ze
                            worden ook wel ‘aandachtsvelders fysieke belasting’ genoemd. Het is niet verplicht met ErgoCoaches te werken, maar de constructie is in de
                            opstartfase van het beleid bewezen effectief. Later kunnen ErgoCoaches een belangrijke rol spelen bij het borgen van het beleid.</p>
                        <ChoiceField id="8.b"/>
                        <h4>8c. Hebben deze ErgoCoaches voldoende kennis en vaardigheden?</h4>
                        <p class="mb-5">Om de kwaliteit van de ErgoCoaches op een voldoende hoog niveau te houden is het belangrijk ze initieel te scholen, regelmatig bij te
                            scholen, ze de mogelijkheid te geven om onderling informatie uit te wisselen en om seminars, studiedagen en beurzen te bezoeken.</p>
                        <ChoiceField id="8.c" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Globaal',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('8.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('8.c').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">Uit <a href="https://www.locomotion.nl/admin/resources/koppelaar-knibbe.pdf" target="_blank">onderzoek</a> blijkt dat
                                            het
                                            hebben van protocollen in de zorgdossiers de rol van de ErgoCoaches versterkt en effectiever maakt.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>8d. Hebben de ErgoCoaches voldoende mogelijkheden om problemen op te lossen?</h4>
                        <p class="mb-5">Het is belangrijk dat de ErgoCoaches de mogelijkheid hebben om zelf, of samen met collega's, ergonomische problemen op te lossen. Directie en direct leidinggevenden moeten actief deze groep
                            ErgoCoaches faciliteren zodat zij daadwerkelijk in staat zijn de problemen samen met hun collega’s op te lossen.</p>
                        <ChoiceField id="8.d" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Globaal',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['physicalStore/getQuestionById']('8.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('8.d').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <p class="mb-0 sub">De Voilà!-methode is een goede manier om ErgoCoaches in hun kracht te zetten. Kijk op de website van <a
                                            href="https://www.locomotion.nl/voila-methode" target="_blank">locomotion.nl</a> voor achtergrondinformatie, films, cartoons,
                                            posters
                                            etc.</p>
                                    </div>
                                </div>
                            </div>
                        </transition>
                        <h4>8e. Hebben deze ErgoCoaches geregeld onderling overleg?</h4>
                        <p class="mb-5">Afhankelijk van de exacte taken en verantwoordelijkheden moeten de ErgoCoaches geregeld bij elkaar kunnen komen. Bij de start van het beleid
                            zal er wat vaker overlegd moeten worden dan in het verloop van de beleidsuitvoering. Kijk <a href="/uploads/ergocoaches-in-beeld.pdf"
                                                                                                                         target="_blank">hier</a> voor meer
                            achtergrondinformatie over het werken met ErgoCoaches.</p>
                        <ChoiceField id="8.e" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet structueel',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                        <h4>8f. Hebben deze ErgoCoaches voldoende tijd om hun werk te kunnen doen?</h4>
                        <p class="mb-5">De ErgoCoaches moeten in het begin structureel vrij geroosterd kunnen worden om hun taken naar behoren te kunnen uitvoeren. De hoeveelheid
                            tijd die hiervoor nodig is hangt af van hun taken, verantwoordelijkheid en de fase waarin het beleid fysieke belasting zich bevindt. We gaan
                            uit van minimaal een dagdeel per week in teams die fysiek belast kunnen worden. Dit onderstreept het belang van de taak en voorkomt dat de
                            taak onderaan de prioriteitenlijst belandt. Later kan dit minder worden. Het is wel aan te bevelen om een bepaalde ondergrens hanteren zodat
                            de ErgoCoaches minstens hun functie als ergonomische waakhond kunnen blijven uitvoeren.</p>
                        <ChoiceField id="8.f" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Niet structueel',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-4'}" class="btn btn-grad mt-3">4. Vertaling Praktijkrichtlijnen naar zorghandelingen<i class="fas fa-arrow-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress/>
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step3',
    components: {ChoiceField, Progress}
}
</script>
