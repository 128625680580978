<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 5.10 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">5. Training en instructie</h3>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Bij meerdere vragen in deze BeleidsSpiegel is aangegeven dat de werkgever de verplichting heeft om werknemers te scholen en voor te lichten over Arbo-risico’s en de
                                    maatregelen die de zorgorganisatie heeft genomen om gezondheidsschade te voorkomen (Artikel 8 van de Arbo-wet). Het gaat er bij deze vraag om dat u een doeltreffend
                                    scholings- en trainingsbeleid heeft en ook dat u, bij een eventuele inspectie, kunt aantonen dat dit het geval is. Er zijn geen concrete eisen aan de frequentie,
                                    duur of inhoud van die scholing. Dat wat optimaal is, zal per zorgbranche en per zorgorganisatie wisselen.
                                </p>
                                <p>
                                    Als werkgever moet u een registratie kunnen overleggen van welke voorlichting of instructie door wie is gegeven en ontvangen.
                                </p>
                                <p class="mb-0">
                                    Van belang is daarnaast dat een eenmalige voorlichting en instructie natuurlijk niet voldoende is. Regelmatig opfrissen van de informatie bij de werknemers is
                                    daarom van belang. Hoe vaak dit moet is niet vastgelegd.
                                </p>
                            </div>
                        </div>
                        <h4>Worden zorgverleners aantoonbaar voorgelicht en getraind met betrekking PSA risico’s?</h4>
                        <ChoiceField id="5.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['psychosocialStore/getQuestionById']('5.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('5.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <div class="sub text-justify">
                                            <p>
                                                Arbo-wet informatie over Voorlichting en onderricht<br>
                                                <i>Artikel 8</i>
                                            </p>
                                            <ol>
                                                <li>De werkgever zorgt ervoor dat de werknemers doeltreffend worden ingelicht over de te verrichten werkzaamheden en de daaraan verbonden risico's,
                                                    alsmede over de maatregelen die erop gericht zijn deze risico's te voorkomen of te beperken. Tevens zorgt de werkgever ervoor dat de werknemers
                                                    doeltreffend worden ingelicht over de wijze waarop de deskundige bijstand, bedoeld in de artikelen 13, 14, 14a en 15, in zijn bedrijf of inrichting
                                                    is georganiseerd.
                                                </li>
                                                <li>
                                                    De werkgever zorgt ervoor dat aan de werknemers doeltreffend en aan hun onderscheiden taken aangepast onderricht wordt verstrekt met betrekking tot
                                                    de arbeidsomstandigheden.
                                                </li>
                                                <li>
                                                    Indien persoonlijke beschermingsmiddelen ter beschikking van de werknemers worden gesteld en indien op arbeidsmiddelen of anderszins beveiligingen
                                                    zijn aangebracht, zorgt de werkgever ervoor dat de werknemers op de hoogte zijn van hun doel en werking en de wijze waarop zij deze dienen te
                                                    gebruiken.
                                                </li>
                                                <li>
                                                    De werkgever ziet toe op de naleving van de instructies en voorschriften gericht op het voorkomen of beperken van de in het eerste lid genoemde
                                                    risico's alsmede op het juiste gebruik van persoonlijke beschermingsmiddelen.
                                                </li>
                                                <li>
                                                    Indien binnen de onderneming werknemers jonger dan 18 jaar werkzaam zijn, houdt de werkgever bij de uitvoering van de in de voorgaande leden
                                                    genoemde verplichtingen in het bijzonder rekening met de aan de jeugdige leeftijd inherente beperkte werkervaring en onvoltooide lichamelijke en
                                                    geestelijke ontwikkeling van deze werknemers.
                                                </li>
                                            </ol>
                                            <p class="mb-0">
                                                Artikel 11 meldt dat werknemers, omgekeerd, de verplichting hebben om mee te werken aan deze scholingen: ‘mede te werken aan het voor hem georganiseerde
                                                onderricht bedoeld in artikel 8’.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-6'}" class="btn btn-grad mt-3">6. Overgang<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep5',
    components: { ChoiceField, Progress }
}
</script>
