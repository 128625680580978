<template>
    <div class="col-lg-8 d-flex justify-content-between align-items-center mb-2">
        <span v-html="this.label"></span>
        <input type="number" class="form-control mb-0 ml-4 small-field" min="1" max="10" step="1" :value="this.getValue()" @change="updateValue($event)">
    </div>
</template>
<script>
export default {
    name:  'NumberField',
    props: ['label', 'id'],
    data() {
        return {
            storeType: this.$route.path.startsWith('/fysieke-belasting') ? 'physicalStore' : 'psychosocialStore'
        }
    },
    methods: {
        updateValue(event) {
            let value = parseInt(event.currentTarget.value);
            value     = (value >= 1 && value <= 10) ? value : 1;

            event.currentTarget.value = value;

            this.$store.commit(this.storeType + '/setQuestion', {
                id:    this.id,
                value: parseFloat(value)
            });

            this.$store.commit(this.storeType + '/setPercentageDone');
        },

        getValue() {
            const question = this.$store.getters[this.storeType + '/getQuestionById'](this.id);

            if(question.value !== null) {
                return question.value;
            }

            return '';
        }
    }
}
</script>