<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 1.1 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">1. Uw huidige situatie: De RI&amp;E als startpunt</h3>
                        <p>
                            Meestal is het startpunt van het PSA-beleid de verplichte Risico-inventarisatie en -evaluatie (RI&E Zorg). De RI&E signaleert Arbo-risico’s. Komt psychosociale
                            arbeidsbelasting als een knelpunt naar voren uit uw RI&E?
                        </p>
                        <h4>1. Psychosociale arbeidsbelasting</h4>

                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p class="mb-0">
                                    Een RI&E signaleert slechts in globale zin Arbo-risico’s, zoals psychosociale arbeidsbelasting. Als dat zo blijkt te zijn, is het relevant om uw beleid
                                    psychosociale arbeidsbelasting nader onder de loep te nemen. U gaat bijvoorbeeld dan met deze BeleidsSpiegel PSA meer de diepte in. Maar ook als dat niet zo is,
                                    kan het relevant zijn om deze BeleidsSpiegel PSA verder in te vullen om wellicht nadere invulling te geven aan uw beleid.
                                </p>
                            </div>
                        </div>
                        <h5>1a. voor zorgverleners</h5>
                        <ChoiceField id="1.a" :options="[{
                                value: 0,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <h5>1b. voor medewerkers van ondersteunde diensten</h5>
                        <ChoiceField id="1.b" :options="[{
                                value: 0,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'Nee',
                                class: 'false'
                            }]" />

                        <h5>1c. voor overige medewerkers</h5>
                        <ChoiceField id="1.c" :options="[{
                                value: 0,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 1,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                    </div>
                </div>
                <transition name="fade-in-up">
                    <div class="hint row justify-content-center mb-5"
                         v-if="((this.$store.getters['psychosocialStore/getQuestionById']('1.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.a').value !== null) || (this.$store.getters['psychosocialStore/getQuestionById']('1.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.b').value !== null) || (this.$store.getters['psychosocialStore/getQuestionById']('1.c').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('1.c').value !== null))">
                        <div class="col-md-8">
                            <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                <p class="mb-0 sub">
                                    Er zijn geen wettelijke eisen gesteld aan de frequentie waarmee een RI&E moet worden uitgevoerd. De eis is dat de RI&E ‘actueel’ moet zijn. De RI&E kan eventueel
                                    ook op onderdelen (bijvoorbeeld alleen bepaalde afdelingen of werkplekken) geactualiseerd worden.
                                </p>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-2'}" class="btn btn-grad mt-3">2. Uw algemene PSA-beleid<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>

<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep1',
    components: { ChoiceField, Progress },
}
</script>
