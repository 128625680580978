<template>
    <div>
        <section class="fullscreen-85 bg-circles pb-0 pt-xs-0">
            <div class="container v-align-transform">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-7 col-12 order-2 order-md-1" data-aos="fade-right">
                        <h1 class="large">Is het beleid fysieke belasting in uw organisatie op orde? </h1>
                        <p>De BeleidsSpiegel is een checklist die u helpt om het beleid fysieke belasting in uw organisatie in kaart te brengen en te ‘spiegelen’ aan de landelijke
                           afspraken zoals ze zijn vastgelegd in de arbocatalogus van uw branche. In het ideale geval kunt u op alle vragen ‘ja’ antwoorden. Zo niet, dan geeft deze
                           online BeleidsSpiegel u tips over hoe u de situatie kunt verbeteren. </p>
                        <div class="btn-container">
                            <router-link :to="{name: 'step-0'}" class="btn btn-grad">Direct invullen</router-link>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-5 col-12 ml-auto order-1 order-md-2 mb-3 mb-md-0" data-aos="fade-left">
                        <img src="/img/header.jpg" class="img-fluid header-img">
                    </div>
                </div>
            </div>
        </section>
        <Partner />
    </div>
</template>

<script>
import Partner from "../../defaults/Partners";

export default {
    name:       'Home',
    components: { Partner }
}
</script>