import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import Vuex from 'vuex';
import Splashscreen from "./components/pages/Splashscreen";

require('bootstrap');
import 'bootstrap/scss/bootstrap.scss';

import './less/app.less';
import About from "./components/pages/Physical/About";
import Faq from "./components/pages/Physical/Faq";
import Step0 from "./components/steps/Physical/Step0";
import Step1 from "./components/steps/Physical/Step1";
import Step2 from "./components/steps/Physical/Step2";
import Step3 from "./components/steps/Physical/Step3";
import Step4 from "./components/steps/Physical/Step4";
import Step5 from "./components/steps/Physical/Step5";
import Step6 from "./components/steps/Physical/Step6";
import Step7 from "./components/steps/Physical/Step7";
import Step8 from "./components/steps/Physical/Step8";
import Step9 from "./components/steps/Physical/Step9";
import Results from "./components/steps/Physical/Results";
import Home from "@/components/pages/Physical/Home";

import PsychosocialAbout from './components/pages/Psychosocial/About';
import PsychosocialFaq from './components/pages/Psychosocial/Faq';
import PsychosocialHome from './components/pages/Psychosocial/Home';
import PsychosocialStep0 from './components/steps/Psychosocial/Step0';
import PsychosocialStep1 from './components/steps/Psychosocial/Step1';
import PsychosocialStep2 from './components/steps/Psychosocial/Step2';
import PsychosocialStep3 from './components/steps/Psychosocial/Step3';
import PsychosocialStep4 from './components/steps/Psychosocial/Step4';
import PsychosocialStep5 from './components/steps/Psychosocial/Step5';
import PsychosocialStep6 from './components/steps/Psychosocial/Step6';
import PsychosocialStep7 from './components/steps/Psychosocial/Step7';
import PsychosocialStep8 from './components/steps/Psychosocial/Step8';
import PsychosocialStep9 from './components/steps/Psychosocial/Step9';
import PsychosocialResults from './components/steps/Psychosocial/Results';

import Copyright from './components/pages/Copyright';
import PrivacyStatement from "@/components/pages/PrivacyStatement";

Vue.use(VueRouter);
Vue.use(Vuex);

const withPrefix = (prefix, routes) =>
    routes.map((route) => {
        route.path = prefix + route.path;
        return route;
    });

const router = new VueRouter({
    routes:               [
        {
            path:       '/',
            components: {
                main: Splashscreen
            }
        }, {
            path:       '/fysieke-belasting',
            name:       'physical-home',
            components: {
                main: Home
            }
        },
        ...withPrefix('/fysieke-belasting', [{
            path:       '/over-de-beleidsspiegel',
            name:       'about',
            components: {
                main: About
            }
        }, {
            path:       '/veelgestelde-vragen',
            name:       'faq',
            components: {
                main: Faq
            }
        },
            ...withPrefix('/stappen', [
                {
                    path:       '/introductie',
                    name:       'step-0',
                    components: {
                        main: Step0
                    }
                }, {
                    path:       '/uw-huidige-situatie',
                    name:       'step-1',
                    components: {
                        main: Step1
                    }
                }, {
                    path:       '/uw-algemeen-beleid',
                    name:       'step-2',
                    components: {
                        main: Step2
                    }
                }, {
                    path:       '/borging-deskundigheid',
                    name:       'step-3',
                    components: {
                        main: Step3
                    }
                }, {
                    path:       '/vertaling-praktijkrichtlijnen-naar-zorghandelingen',
                    name:       'step-4',
                    components: {
                        main: Step4
                    }
                }, {
                    path:       '/vertaling-praktijkrichtlijnen-naar-niet-client-gebonden-handelingen',
                    name:       'step-5',
                    components: {
                        main: Step5
                    }
                }, {
                    path:       '/bijzondere-situaties',
                    name:       'step-6',
                    components: {
                        main: Step6
                    }
                }, {
                    path:       '/training-en-instructie',
                    name:       'step-7',
                    components: {
                        main: Step7
                    }
                }, {
                    path:       '/hulpmiddelen',
                    name:       'step-8',
                    components: {
                        main: Step8
                    }
                }, {
                    path:       '/ergonomie-van-de-omgeving',
                    name:       'step-9',
                    components: {
                        main: Step9
                    }
                }, {
                    path:       '/resultaten',
                    name:       'step-results',
                    components: {
                        main: Results
                    }
                }
            ])
        ]), {
            path:       '/psychosociale-arbeidsbelasting',
            name:       'psychosocial-home',
            components: {
                main: PsychosocialHome
            }
        },
        ...withPrefix('/psychosociale-arbeidsbelasting', [{
            path:       '/over-de-beleidsspiegel',
            name:       'psychosocial-about',
            components: {
                main: PsychosocialAbout
            }
        }, {
            path:       '/veelgestelde-vragen',
            name:       'psychosocial-faq',
            components: {
                main: PsychosocialFaq
            }
        },
            ...withPrefix('/stappen', [
                {
                    path:       '/introductie',
                    name:       'psychosocial-step-0',
                    components: {
                        main: PsychosocialStep0
                    }
                }, {
                    path:       '/uw-huidige-situatie-de-ri-en-e-als-startpunt',
                    name:       'psychosocial-step-1',
                    components: {
                        main: PsychosocialStep1
                    }
                }, {
                    path:       '/uw-algemene-psa-beleid',
                    name:       'psychosocial-step-2',
                    components: {
                        main: PsychosocialStep2
                    }
                }, {
                    path:       '/het-veiligheidsklimaat-in-uw-organisatie',
                    name:       'psychosocial-step-3',
                    components: {
                        main: PsychosocialStep3
                    }
                }, {
                    path:       '/psychosociale-arbeidsbelasting-en-de-subthemas',
                    name:       'psychosocial-step-4',
                    components: {
                        main: PsychosocialStep4
                    }
                }, {
                    path:       '/training-en-instructie',
                    name:       'psychosocial-step-5',
                    components: {
                        main: PsychosocialStep5
                    }
                }, {
                    path:       '/overgang',
                    name:       'psychosocial-step-6',
                    components: {
                        main: PsychosocialStep6
                    }
                }, {
                    path:       '/ouder-worden-en-psa-beleid',
                    name:       'psychosocial-step-7',
                    components: {
                        main: PsychosocialStep7
                    }
                }, {
                    path:       '/werkruimtes-en-veiligheid',
                    name:       'psychosocial-step-8',
                    components: {
                        main: PsychosocialStep8
                    }
                }, {
                    path:       '/begroting',
                    name:       'psychosocial-step-9',
                    components: {
                        main: PsychosocialStep9
                    }
                }, {
                    path:       '/resultaten',
                    name:       'psychosocial-step-results',
                    components: {
                        main: PsychosocialResults
                    }
                }
            ])
        ]), {
            path:       '/copyright',
            name:       'copyright',
            components: {
                main: Copyright
            }
        }, {
            path:       '/privacyverklaring',
            name:       'privacyStatement',
            components: {
                main: PrivacyStatement
            }
        }
    ],
    mode:                 'history',
    linkExactActiveClass: 'active',
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});

Vue.config.productionTip = false;

const stores = new Vuex.Store({
    modules: {
        physicalStore:     {
            namespaced: true,
            state:      () => ({
                fullName:         null,
                organizationName: null,
                percentageDone:   0,
                questions:        [
                    {id: '1.a', value: null, textValue: null},
                    {id: '1.b', value: null, textValue: null},

                    {id: '2.a', value: null, textValue: null},

                    {id: '3.a', value: null, textValue: null},
                    {id: '3.b', value: null, textValue: null},

                    {id: '3.c.a', value: null, textValue: null},
                    {id: '3.c.b', value: null, textValue: null},
                    {id: '3.c.c', value: null, textValue: null},
                    {id: '3.c.d', value: null, textValue: null},
                    {id: '3.c.e', value: null, textValue: null},

                    {id: '4.a', value: null, textValue: null},

                    {id: '5.a', value: null, textValue: null},

                    {id: '6.a', value: null, textValue: null},

                    {id: '7.a', value: null, textValue: null},

                    {id: '8.a', value: null, textValue: null},
                    {id: '8.b', value: null, textValue: null},
                    {id: '8.c', value: null, textValue: null},
                    {id: '8.d', value: null, textValue: null},
                    {id: '8.e', value: null, textValue: null},
                    {id: '8.f', value: null, textValue: null},

                    {id: '9.a', value: null, textValue: null},
                    {id: '9.b', value: null, textValue: null},
                    {id: '9.c', value: null, textValue: null},
                    {id: '9.d', value: null, textValue: null},
                    {id: '9.e', value: null, textValue: null},
                    {id: '9.f', value: null, textValue: null},

                    {id: '10.a', value: null, textValue: null},
                    {id: '10.b', value: null, textValue: null},
                    {id: '10.c', value: null, textValue: null},
                    {id: '10.d', value: null, textValue: null},

                    {id: '11.a', value: null, textValue: null},
                    {id: '11.b', value: null, textValue: null},
                    {id: '11.c', value: null, textValue: null},
                    {id: '11.d', value: null, textValue: null},
                    {id: '11.e', value: null, textValue: null},
                    {id: '11.f', value: null, textValue: null},
                    {id: '11.g', value: null, textValue: null},
                    {id: '11.h', value: null, textValue: null},
                    {id: '11.i', value: null, textValue: null},
                    {id: '11.j', value: null, textValue: null},
                    {id: '11.k', value: null, textValue: null},
                    {id: '11.l', value: null, textValue: null},
                    {id: '11.m', value: null, textValue: null},
                    {id: '11.n', value: null, textValue: null},

                    {id: '12.a', value: null, textValue: null},
                    {id: '12.b', value: null, textValue: null},
                    {id: '12.c', value: null, textValue: null},
                    {id: '12.d', value: null, textValue: null},
                    {id: '12.e', value: null, textValue: null},
                    {id: '12.f', value: null, textValue: null},

                    {id: '13.a', value: null, textValue: null},
                    {id: '13.b', value: null, textValue: null},
                    {id: '13.c', value: null, textValue: null},
                    {id: '13.d', value: null, textValue: null},

                    {id: '14', value: null, textValue: null},
                    {id: '14.a', value: null, textValue: null},
                    {id: '14.b', value: null, textValue: null},
                    {id: '14.c', value: null, textValue: null},
                    {id: '14.d', value: null, textValue: null},
                    {id: '14.e', value: null, textValue: null},
                    {id: '14.f', value: null, textValue: null},
                    {id: '14.g', value: null, textValue: null},
                    {id: '14.h', value: null, textValue: null},

                    {id: '15.a', value: null, textValue: null},
                    {id: '16.a', value: null, textValue: null},
                    {id: '17.a', value: null, textValue: null},
                    {id: '18.a', value: null, textValue: null},
                    {id: '19.a', value: null, textValue: null},
                    {id: '20.a', value: null, textValue: null},
                ]
            }),
            getters:    {
                getQuestionById:     (state) => (id) => {
                    return state.questions.find(question => question.id === id);
                },
                getName:             (state) => () => {
                    return state.fullName;
                },
                getOrganizationName: (state) => () => {
                    return state.organizationName;
                }
            },
            mutations:  {
                initialiseStore(state) {
                    if(sessionStorage.getItem('physicalStore')) {
                        Object.assign(state, JSON.parse(sessionStorage.getItem('physicalStore')))
                    }
                },
                setFullName(state, fullName) {
                    state.fullName = fullName;
                },
                setOrganizationName(state, organizationName) {
                    state.organizationName = organizationName;
                },
                setQuestion(state, payload) {
                    for(let index in state.questions) {
                        if(state.questions[index].id === payload.id) {
                            state.questions[index].value = payload.value;

                            if(payload.textValue) {
                                state.questions[index].textValue = payload.textValue;
                            }
                        }
                    }
                },
                setPercentageDone(state) {
                    let totalQuestions    = state.questions.length;
                    let answeredQuestions = 0;
                    for(let index in state.questions) {
                        if(state.questions[index].value !== null) {
                            answeredQuestions++;
                        }
                    }

                    state.percentageDone = Math.round((answeredQuestions / totalQuestions) * 100);
                }
            }
        },
        psychosocialStore: {
            namespaced: true,
            state:      () => ({
                fullName:         null,
                organizationName: null,
                percentageDone:   0,
                questions:        [
                    {id: '1.a', value: null, textValue: null},
                    {id: '1.b', value: null, textValue: null},
                    {id: '1.c', value: null, textValue: null},

                    {id: '2.a.a', value: null, textValue: null},
                    {id: '2.a.b', value: null, textValue: null},

                    {id: '2.b', value: null, textValue: null},

                    {id: '2.c', value: null, textValue: null},

                    {id: '2.d', value: null, textValue: null},

                    {id: '2.e', value: null, textValue: null},

                    {id: '2.f', value: null, textValue: null},

                    {id: '2.g', value: null, textValue: null},

                    {id: '2.g', value: null, textValue: null},

                    {id: '3.a', value: null, textValue: null},
                    {id: '3.b', value: null, textValue: null},
                    {id: '3.c', value: null, textValue: null},
                    {id: '3.d', value: null, textValue: null},
                    {id: '3.e', value: null, textValue: null},

                    {id: '4.a.a', value: null, textValue: null},
                    {id: '4.a.b', value: null, textValue: null},

                    {id: '4.b.a', value: null, textValue: null},
                    {id: '4.b.b', value: null, textValue: null},

                    {id: '4.c.a', value: null, textValue: null},
                    {id: '4.c.b', value: null, textValue: null},
                    {id: '4.c.c', value: null, textValue: null},
                    {id: '4.c.d', value: null, textValue: null},

                    {id: '4.d.a', value: null, textValue: null},
                    {id: '4.d.b', value: null, textValue: null},

                    {id: '4.e.a', value: null, textValue: null},
                    {id: '4.e.b', value: null, textValue: null},

                    {id: '5.a', value: null, textValue: null},

                    {id: '6.a', value: null, textValue: null},
                    {id: '6.b', value: null, textValue: null},

                    {id: '7.a', value: null, textValue: null},
                    {id: '7.b', value: null, textValue: null},

                    {id: '8.a', value: null, textValue: null},

                    {id: '9.a', value: null, textValue: null},
                ]
            }),
            getters:    {
                getQuestionById:     (state) => (id) => {
                    return state.questions.find(question => question.id === id);
                },
                getName:             (state) => () => {
                    return state.fullName;
                },
                getOrganizationName: (state) => () => {
                    return state.organizationName;
                }
            },
            mutations:  {
                initialiseStore(state) {
                    if(sessionStorage.getItem('psychosocialStore')) {
                        Object.assign(state, JSON.parse(sessionStorage.getItem('psychosocialStore')))
                    }
                },
                setFullName(state, fullName) {
                    state.fullName = fullName;
                },
                setOrganizationName(state, organizationName) {
                    state.organizationName = organizationName;
                },
                setQuestion(state, payload) {
                    for(let index in state.questions) {
                        if(state.questions[index].id === payload.id) {
                            state.questions[index].value = payload.value;
                            if(payload.textValue) {
                                state.questions[index].textValue = payload.textValue;
                            }
                        }
                    }
                },
                setPercentageDone(state) {
                    let totalQuestions    = state.questions.length;
                    let answeredQuestions = 0;
                    for(let index in state.questions) {
                        if(state.questions[index].value !== null) {
                            answeredQuestions++;
                        }
                    }

                    state.percentageDone = Math.round((answeredQuestions / totalQuestions) * 100);
                }
            }
        }
    }
});

stores.subscribe((mutation, state) => {
    if(mutation.type.startsWith('physicalStore')) {
        sessionStorage.setItem('physicalStore', JSON.stringify(state.physicalStore));
    }

    if(mutation.type.startsWith('psychosocialStore')) {
        sessionStorage.setItem('psychosocialStore', JSON.stringify(state.psychosocialStore));
    }
});

Vue.use(VueGtag, {
    config: {id: "UA-40339467-9"}
}, router);

new Vue({
    router,
    store: stores,
    beforeCreate() {
        this.$store.commit('physicalStore/initialiseStore');
        this.$store.commit('psychosocialStore/initialiseStore');
    },
    render: h => h(App),
}).$mount('#app')


document.querySelectorAll('a.dropdown-item').forEach(element => {
    element.addEventListener('click', function() {
        if(window.innerWidth < 768) {
            document.querySelector('.navbar-toggler').click();
        }
    });
});

window.onbeforeunload = function(e) {
    e = e || window.event;

    if(e) {
        e.returnValue = 'Sure?';
    }

    return 'Sure?';
};
