<template>
    <div>
        <div id="overlay-export">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h4>Bezig met het genereren van de PDF. Dit kan even duren...</h4>
        </div>
        <div id="exportable">
            <section class="bg-secondary pt-12 pb-5 first-section">
                <div class="container">
                    <div class="row justify-content-center" data-aos="fade-up">
                        <div class="col-md-12 text-center mb-3">
                            <img src="/img/logo-fb.svg" class="logo mb-5" v-if="this.exporting">
                            <h3 class="color-primary">Resultaten</h3>
                            <p>Op basis van de ingevoerde gegevens is er een score berekend per 'dimensie'. In onderstaande radar wordt deze score vergeleken met de landelijke scores
                               per dimensie. <br><strong>Let op:</strong> Deze website onthoudt uw
                               gegevens niet, dus bewaar de pdf zorgvuldig.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 text-right">
                            <ul class="no-bullets">
                                <li>Naam</li>
                                <li>Organisatie</li>
                                <li>Datum</li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul class="no-bullets">
                                <li class="bold heading-font">{{ name }}</li>
                                <li class="bold heading-font">{{ organizationName }}</li>
                                <li class="bold heading-font">{{ date }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-8 mx-auto">
                            <ResultChart :key="resultsChartKey" />
                        </div>
                    </div>
                </div>
            </section>
            <section class="pt-0">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 class="d-block">Toelichting per dimensie</h4>
                            <div id="accordion">
                                <div class="accordion-item mb-3">
                                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse0" aria-expanded="false" aria-controls="collapse0">
                                    <span class="accordion-title">
                                        Scores, toelichtingen en tips
                                    </span>
                                    </div>
                                    <div id="collapse0" class="collapse">
                                        <div class="accordion-body">
                                            <label><input type="checkbox" v-model="showExplanations"> Toon toelichtingen</label>
                                            <label class="ml-3"><input type="checkbox" v-model="showTips"> Toon tips</label>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>1. Komt fysieke belasting als een knelpunt naar voren uit de RI&E?</h4>
                                                        <p class="mb-0">
                                                            Een RI&E signaleert globaal Arbo risico’s, zoals fysieke belasting. Als dat zo blijkt te zijn, is het relevant om uw beleid fysieke
                                                            belasting te checken en gaat uw met deze BeleidsSpiegel en de TilThermometer (zie vraag 2 van deze BeleidsSpiegel) de diepte in.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">1a. voor zorgverleners</p>
                                                <p v-html="getAnswer('1.a') + getScore('1.a')"></p>

                                                <p class="mb-0 bold">1b. voor medewerkers van ondersteunde diensten</p>
                                                <p v-html="getAnswer('1.b') + getScore('1.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && ((this.$store.getters['physicalStore/getQuestionById']('1.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('1.a').value !== null) ||
                                                            (this.$store.getters['physicalStore/getQuestionById']('1.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('1.b').value !== null))">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Er zijn geen wettelijke eisen gesteld aan de frequentie waarmee een RI&E moet worden uitgevoerd. De eis is dat de RI&E
                                                                                ‘actueel’
                                                                                moet zijn. De RI&E kan eventueel ook op onderdelen (bijvoorbeeld alleen bepaalde afdelingen of werkplekken) geactualiseerd
                                                                                worden.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>2. Beschikt u over een actuele TilThermometer?</h4>
                                                        <p class="mb-0">
                                                            De noodzaak tot het voeren van een preventiebeleid fysieke overbelasting hangt nauw samen met de mobiliteit van de cliënten. Nog
                                                            belangrijker is in hoeverre u bij immobiele cliënten adequate maatregelen heeft getroffen om fysieke overbelasting bij zorgverleners
                                                            te voorkomen. De TilThermometer geeft aan in hoeverre dit het geval is.
                                                        </p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">2a.</p>
                                                <p v-html="getAnswer('2.a') + getScore('2.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('2.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('2.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Vul uw TilThermometer in via www.tilthermometer.nl</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>3. Voert uw organisatie een structureel preventiebeleid fysieke belasting?</h4>
                                                        <p class="mb-0">Het antwoord op deze vraag is belangrijk, maar wel vrij subjectief. Door middel van het doorlopen van de onderstaande vragen uit deze
                                                                        BeleidsSpiegel krijgt u een beter beeld van de kwaliteit van uw beleid fysieke belasting. En dus ook van de verbeterpunten. </p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">3a. voor zorgverleners</p>
                                                <p v-html="getAnswer('3.a') + getScore('3.a')"></p>

                                                <p class="mb-0 bold">3b. voor medewerkers van ondersteunde diensten</p>
                                                <p v-html="getAnswer('3.b') + getScore('3.b')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>4. Wordt er in elke begroting rekening gehouden met een reële post voor het terugdringen of aanvaardbaar houden van fysieke belasting?</h4>
                                                        <p class="mb-0">Om een succesvol fysieke belasting te kunnen voeren is het noodzakelijk hiervoor jaarlijks een reële post op te nemen in de begroting. </p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">4a.</p>
                                                <p v-html="getAnswer('4.a') + getScore('4.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('4.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('4.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">De omvang van de post hangt af van de problematiek die is gesignaleerd en de doelstellingen die op grond daarvan zijn
                                                                                geformuleerd.
                                                                                Belangrijk is echter dat hiervoor een basisbedrag gereserveerd wordt, dat jaarlijks op basis van monitoring (bijvoorbeeld door
                                                                                middel van de TilThermometer, Risico(Rug)Radar of BeleidsSpiegel) kan worden bijgesteld. Voor het doorrekenen van het effect van
                                                                                investeringen kunt u gebruik maken van businesscases.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>5. Beschikt uw organisatie over een monitoringsysteem om het beleid fysieke belasting te ondersteunen?</h4>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">5a.</p>
                                                <p v-html="getAnswer('5.a') + getScore('5.a')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>6. Sluit het beleid fysieke belasting aan op het beleid van uw organisatie rondom bijvoorbeeld vallen, decubitus, incontinentie, onvrijwillige zorg,
                                                            etc.?</h4>
                                                        <p class="mb-0">Omdat aandachtsvelden als ergonomie, vallen, incontinentie, mobiliteit, voeding, onvrijwillige zorg, decubitus, etc. sterk met elkaar
                                                                        samenhangen verdient het aanbeveling te streven naar synergie waardoor uw beleid effectiever wordt.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">6a.</p>
                                                <p v-html="getAnswer('6.a') + getScore('6.a')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('6.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('6.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Dit <a href="https://www.locomotion.nl/admin/resources/030-032arb10art03knibbe.pdf" target="_blank"> artikel</a> gaat in op de
                                                                                mogelijkheden die ontstaan als we aandachtsvelden als ergonomie, vallen, incontinentie, mobiliteit, voeding, onvrijwillige zorg
                                                                                ,
                                                                                decubitus, etc. in beleid met elkaar verbinden. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>7. Zijn de Praktijkrichtlijnen Fysieke Belasting de basis van het beleid fysieke belasting in uw organisatie?</h4>
                                                        <p class="mb-0">Zowel voor de zorgverleners als voor de medewerkers van ondersteunende diensten zijn de Praktijkrichtlijnen Fysieke Belasting vastgelegd in
                                                                        de Arbocatalogus van uw zorgbranche. De Inspectie SZW, voorheen de Arbeidsinspectie, controleert hierop. Deze grenzen voor fysieke belasting
                                                                        (in feite het moment waarop fysieke belasting verandert in fysieke overbelasting) moeten echter wel voor iedereen duidelijk zijn en
                                                                        bekrachtigd worden in het organisatiebeleid.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">7a.</p>
                                                <p v-html="getAnswer('7.a') + getScore('7.a')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('7.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('7.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op uw Arbocatalogus voor de meeste recente versie van de Praktijkrichtlijnen Fysieke Belasting zoals die uw branche zijn
                                                                                afgesproken. En op <a href="https://free-learning.nl/modules/basisfysiekgezondwerken/start.html"
                                                                                                      target="_blank">free-learning.nl</a> vindt u een Free Learning module 'Basis Fysiek Gezond Werken’ waarin
                                                                                de
                                                                                Praktijkrichtlijnen centraal staan.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>8a. Is er een platform, commissie of werkgroep die zich bezighoudt met het beleid fysieke belasting?</h4>
                                                        <p class="mb-0">Om het beleid fysieke belasting voortdurend te kunnen bijsturen is communicatie tussen beleidsmakers en uitvoerenden essentieel. Het ligt
                                                                        voor de hand daarvoor een werkgroep of commissie in het leven te roepen. Naar mate het beleid beter is geïmplementeerd kan deze groep wat
                                                                        meer op de achtergrond actief worden en vooral beschikbaar zijn als vraagbaak bij probleemsituaties.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">8a.</p>
                                                <p v-html="getAnswer('8.a') + getScore('8.a')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>8b. Zijn er direct op de werkvloer ErgoCoaches actief?</h4>
                                                        <p class="mb-0">ErgoCoaches zijn 'gewone' medewerkers die een extra verantwoordelijkheid en deskundigheid hebben op het gebied van fysieke belasting. Ze
                                                                        worden ook wel ‘aandachtsvelders fysieke belasting’ genoemd. Het is niet verplicht met ErgoCoaches te werken, maar de constructie is in de
                                                                        opstartfase van het beleid bewezen effectief. Later kunnen ErgoCoaches een belangrijke rol spelen bij het borgen van het beleid.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">8b.</p>
                                                <p v-html="getAnswer('8.b') + getScore('8.b')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>8c. Hebben deze ErgoCoaches voldoende kennis en vaardigheden?</h4>
                                                        <p class="mb-0">Om de kwaliteit van de ErgoCoaches op een voldoende hoog niveau te houden is het belangrijk ze initieel te scholen, regelmatig bij te
                                                                        scholen, ze de mogelijkheid te geven om onderling informatie uit te wisselen en om seminars, studiedagen en beurzen te bezoeken.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">8c.</p>
                                                <p v-html="getAnswer('8.c') + getScore('8.c')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('8.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('8.c').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Uit <a href="https://www.locomotion.nl/admin/resources/koppelaar-knibbe.pdf" target="_blank">onderzoek</a> blijkt dat
                                                                                het
                                                                                hebben van protocollen in de zorgdossiers de rol van de ErgoCoaches versterkt en effectiever maakt.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>8d. Hebben de ErgoCoaches voldoende mogelijkheden om problemen op te lossen?</h4>
                                                        <p class="mb-0">Het is belangrijk dat de ErgoCoaches de mogelijkheid hebben om zelf, of samen met collega's, ergonomische problemen op te lossen. Managers
                                                                        moeten ruimte geven en het vertrouwen uitstralen dat de ErgoCoaches zelf in staat zijn de problemen met hun collega’s op te lossen.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">8d.</p>
                                                <p v-html="getAnswer('8.d') + getScore('8.d')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('8.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('8.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">De Voilà!-methode is een goede manier om ErgoCoaches in hun kracht te zetten. Kijk op de website van <a
                                                                href="https://www.locomotion.nl/voila-methode" target="_blank">locomotion.nl</a> voor achtergrondinformatie, films, cartoons,
                                                                                posters
                                                                                etc.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>8e. Hebben deze ErgoCoaches geregeld onderling overleg?</h4>
                                                        <p class="mb-0">Afhankelijk van de exacte taken en verantwoordelijkheden moeten de ErgoCoaches geregeld bij elkaar kunnen komen. Bij de start van het beleid
                                                                        zal er wat vaker overlegd moeten worden dan in het verloop van de beleidsuitvoering. Kijk <a href="/uploads/ergocoaches-in-beeld.pdf"
                                                                                                                                                                     target="_blank">hier</a> voor meer
                                                                        achtergrondinformatie over het werken met ErgoCoaches.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">8e.</p>
                                                <p v-html="getAnswer('8.e') + getScore('8.e')"></p>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>8f. Hebben deze ErgoCoaches voldoende tijd om hun werk te kunnen doen?</h4>
                                                        <p class="mb-0">De ErgoCoaches moeten in het begin structureel vrij geroosterd kunnen worden om hun taken naar behoren te kunnen uitvoeren. De hoeveelheid
                                                                        tijd die hiervoor nodig is hangt af van hun taken, verantwoordelijkheid en de fase waarin het beleid fysieke belasting zich bevindt. We gaan
                                                                        uit van minimaal een dagdeel per week in teams die fysiek belast kunnen worden. Dit onderstreept het belang van de taak en voorkomt dat de
                                                                        taak onderaan de prioriteitenlijst belandt. Later kan dit minder worden. Het is wel aan te bevelen om een bepaalde ondergrens hanteren zodat
                                                                        de ErgoCoaches minstens hun functie als ergonomische waakhond kunnen blijven uitvoeren.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">8f.</p>
                                                <p v-html="getAnswer('8.f') + getScore('8.f')"></p>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>9a. Wordt er van alle mogelijk fysiek belastende handelingen rondom cliënten per cliënt vooraf een zorgvuldige inschatting van de risico's
                                                            gemaakt?</h4>
                                                        <p class="mb-0">Om ergonomisch te kunnen werken is het nodig om bij elke cliënt een zorgvuldige inschatting te maken van de risico’s die fysiek belastende
                                                                        handelingen met zich meebrengen. Het gaat daarbij niet alleen om de transfers, denk ook aan het manoeuvreren met rollend materiaal (bedden,
                                                                        tilliften, karren, etc.), het aan- of uitrekken van steunkousen en het wassen, douchen en baden. Voor elk van deze handelingen zijn
                                                                        Praktijkrichtlijnen Fysieke Belasting geformuleerd.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">9a.</p>
                                                <p v-html="getAnswer('9.a') + getScore('9.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('9.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op uw Arbocatalogus voor de meeste recente versie van de Praktijkrichtlijnen Fysieke Belasting zoals die uw branche
                                                                                zijn afgesproken.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>9b. Wordt op basis van die inschatting een veilige keuze gemaakt in de manier waarop de zorghandeling wordt uitgevoerd?</h4>
                                                        <p class="mb-0">Op basis van gemaakte inschatting moeten er afspraken gemaakt worden over hoe de zorghandelingen bij de betreffende cliënt worden
                                                                        uitgevoerd. Deze afspraken moeten conform de Praktijkrichtlijnen Fysieke Belasting zijn.</p>
                                                    </div>
                                                </div>

                                                <p class="mb-0 bold">9b.</p>
                                                <p v-html="getAnswer('9.b') + getScore('9.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('9.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op GoedGebruik www.goedgebruik.nl voor ruim 250 instructiefilms die conform de Praktijkrichtlijnen Fysieke
                                                                                Belasting
                                                                                worden uitgevoerd.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>9c. Wordt bij het maken van deze afspraken het stimuleren van zelfredzaamheid van de cliënt nadrukkelijk meegenomen?</h4>
                                                        <p class="mb-0">Dit geldt voor cliënten met Mobiliteitsklassen A-D. Hoe groter de zelfredzaamheid van cliënten, hoe minder kans op fysieke overbelasting van
                                                                        de zorgverleners. Daarnaast is het zorginhoudelijk in de meeste gevallen van groot belang zelfredzaamheid te stimuleren. </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">9c.</p>
                                                <p v-html="getAnswer('9.c') + getScore('9.c')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('9.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.c').value !== null">
                                                    <div class="col-md-8">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Met de ZelfredzaamheidsRadar check je op 15 domeinen de zelfredzaamheid van de cliënt en zoek je op basis daarvan naar
                                                                                mogelijkheden om deze te verbeteren of te onderhouden. Kijk op www.zelfredzaamheidsradar.nl voor meer informatie, films
                                                                                en
                                                                                een Free Learning module.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>9d. Worden deze afspraken vastgelegd?</h4>
                                                        <p class="mb-0">Het is nodig dat de afspraken, die conform de Praktijkrichtlijnen Fysieke Belasting zijn gemaakt, worden vastgelegd in het zorgdossier.
                                                                        Bijvoorbeeld op een speciaal Til- of Transferprotocol.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">9d.</p>
                                                <p v-html="getAnswer('9.d') + getScore('9.d')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('9.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Uitgebreide achtergrondinformatie over til-, transfer- en mobiliteitsprotocollen is te vinden in de publicatie
                                                                                'Transferprotocollen, hoe en wat?'</p>
                                                            <a href="/uploads/transferprotocollen-2015.pdf" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>9e. Worden deze afspraken doorgaans nageleefd?</h4>
                                                        <p class="mb-0">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de zorgverleners niet alleen te betrekken bij het proces, maar daarin nóg een
                                                                        stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">9e.</p>
                                                <p v-html="getAnswer('9.e') + getScore('9.e')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('9.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.e').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de zorgverleners niet alleen te
                                                                                betrekken
                                                                                bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen
                                                                                op te
                                                                                lossen. De Voilà!-methode biedt hiervoor bijvoorbeeld een goede structuur.</p>
                                                            <a href="https://www.locomotion.nl/voila-methode" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>9f. Wordt op naleving van die afspraken toegezien?</h4>
                                                        <p class="mb-0">Het is belangrijk het naleven van de afspraken te monitoren. Dit kan binnen het normale zorgproces, maar ook via de ErgoCoaches, de
                                                                        Transferprotocollen, de TilThermometer of door het meewerken op een afdeling. Op deze manier wordt het toezien op de afspraken geen doel op
                                                                        zichzelf, maar levert het informatie op die gebruikt kan worden om het beleid zo nodig bij te sturen. Het ‘toezien op afspraken’ is daarmee
                                                                        vooral een vorm van monitoring geworden en minder een vorm van controle. </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">9f.</p>
                                                <p v-html="getAnswer('9.f') + getScore('9.f')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('9.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('9.f').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over 'hoe krijg ik mijn collega's zo ver?'. </p>
                                                            <a href="https://www.free-learning.nl/modules/hoe-krijg-ik-mijn-collega's-zover/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>10a. Wordt er van alle mogelijk fysiek belastende, niet-cliënt gebonden handelingen vooraf een zorgvuldige inschatting van de risico's gemaakt?</h4>
                                                        <p class="mb-0">Om ergonomisch te kunnen werken is het nodig om van elke mogelijk fysiek belastende, niet-cliëntgebonden handeling een zorgvuldige
                                                                        inschatting te maken van de risico’s. Leidend zijn daarbij de Praktijkrichtlijnen Fysieke Belasting die zijn geformuleerd voor de 15
                                                                        hoofdbronnen van fysieke belasting voor medewerkers van ondersteunende diensten.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">10a.</p>
                                                <p v-html="getAnswer('10.a') + getScore('10.a')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips &&this.$store.getters['physicalStore/getQuestionById']('10.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">De Praktijkrichtlijnen Fysieke Belasting voor medewerkers van ondersteunende diensten zijn te vinden in uw
                                                                                Arbocatalogus.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>10b. Wordt op basis van die inschatting gekozen voor een gezonde werkmethode?</h4>
                                                        <p class="mb-0">Denk hierbij bijvoorbeeld aan afspraken over de verwerking van het vuilnis, waszakken, het gebruik van hulpmiddelen om hoog te kunnen werken
                                                                        of het tillen van meubilair bij een interne verhuizing.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">10b.</p>
                                                <p v-html="getAnswer('10.b') + getScore('10.b')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('10.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op GoedGebruik www.goedgebruik.nl voor films over werken conform de Praktijkrichtlijnen Fysieke Belasting voor
                                                                                medewerkers van ondersteunende diensten.</p>
                                                            <a href="https://www.goedgebruik.nl" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>10c. Worden deze afspraken doorgaans nageleefd?</h4>
                                                        <p class="mb-0">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de medewerkers niet alleen te betrekken bij het proces, maar daarin nóg een
                                                                        stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen op te lossen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">10c.</p>
                                                <p v-html="getAnswer('10.c') + getScore('10.c')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('10.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.c').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Om het naleven van gemaakte afspraken zoveel mogelijk te stimuleren is het zinvol de medewerkers niet alleen te
                                                                                betrekken
                                                                                bij het proces, maar daarin nóg een stap verder te gaan: ze de ruimte en het vertrouwen te geven om de eigen problemen
                                                                                op te
                                                                                lossen. De Voilà-methode biedt hiervoor een goede structuur. Kijk op de website van LOCOmotion voor
                                                                                achtergrondinformatie,
                                                                                films, cartoons, posters etc.</p>
                                                            <a href="https://www.locomotion.nl/voila-methode" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>10d. Wordt op naleving van die afspraken toegezien?</h4>
                                                        <p class="mb-0">Het is belangrijk het naleven van de afspraken te monitoren. Dit kan binnen de reguliere werkprocessen, maar ook via de ErgoCoaches, de
                                                                        Risico(Rug)Radar of door het meewerken op een afdeling. Op deze manier wordt het toezien op de afspraken geen doel op zichzelf, maar levert
                                                                        het informatie op die gebruikt kan worden om het beleid zo nodig bij te sturen. Het ‘toezien op afspraken’ is daarmee meer een vorm van
                                                                        monitoring geworden en minder een vorm van controle.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">10d.</p>
                                                <p v-html="getAnswer('10.d') + getScore('10.d')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('10.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('10.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over 'hoe krijg ik mijn collega's zo ver?'.</p>
                                                            <a href="https://www.free-learning.nl/modules/hoe-krijg-ik-mijn-collega's-zover/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h3 class="color-primary">11. Is er in uw organisatie beleid op schrift aanwezig ten aanzien van fysieke belasting in bijzondere gevallen?</h3>
                                                        <h4>11a. Voor het omgaan met agressieve cliënten?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11a.</p>
                                                <p v-html="getAnswer('11.a') + getScore('11.a')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11b Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Agressie door cliënten in de basiszorg kan het risico op het krijgen van klachten aan het bewegingsapparaat verhogen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11b.</p>
                                                <p v-html="getAnswer('11.b') + getScore('11.b')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Op Blijf Inzetbaar <a href="https://www.blijfinzetbaar.nl/" target="_blank">blijfinzetbaar.nl</a> vindt u relevant en
                                                                                praktisch materiaal om agressie in de basiszorg te voorkomen. Kijk ook op <a href="https://www.free-learning.nl"
                                                                                                                                                             target="_blank">Free Learning</a> voor
                                                                                e-learning modules over het voorkomen en omgaan met agressie.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11c. Voor fysiek zwaar werk voor zwangeren?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11c.</p>
                                                <p v-html="getAnswer('11.c') + getScore('11.c')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11d Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Voor fysiek zwaar werk voor zwangeren zijn zorgorganisaties gehouden aan wettelijke bepalingen en regelgeving. De Inspectie SZW legt deze
                                                                        wet als volgt uit: gedurende de zwangerschap en eerste maanden na de bevalling mogen vrouwen niet worden verplicht frequent (dat wil zeggen
                                                                        meer dan eenmaal per uur) meer dan vijf kilo te tillen of te dragen. Voor meer incidentele activiteiten geldt dat vrouwen in de
                                                                        bovengenoemde periode niet verplicht kunnen worden meer dan 10 kilo te tillen of te dragen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11d.</p>
                                                <p v-html="getAnswer('11.d') + getScore('11.d')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Arbocatalogus VVT voor meer informatie over zwangerschap en fysieke belasting.</p>
                                                            <a href="https://www.arbocatalogusvvt.nl/thema/zwangerschap-en-arbeid/fysieke-belasting-en-zwangerschap/algemeen/" class="btn btn-hints"
                                                               target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11e. Voor fysiek zwaar werk voor oudere werknemers?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11e.</p>
                                                <p v-html="getAnswer('11.e') + getScore('11.e')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11f. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Gezien de stijgende leeftijd van de zorgverleners en de daaraan gekoppelde extra risico’s op verzuim en uitstroom, is behoud van medewerkers
                                                                        en dus werklastbeheersing en leeftijdsbewust vitaliteits- en personeelsbeleid steeds noodzakelijker. </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11f.</p>
                                                <p v-html="getAnswer('11.f') + getScore('11.f')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.f').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">'Slim ouder worden in de zorg' is een praktisch boekje voor zorgverleners over de uitdagingen van het ouder worden in de
                                                                                zorg. Bestel het boekje via <a href="https://www.locomotion.nl/winkeltje" target="_blank">locomotion.nl/winkeltje</a> of
                                                                                download het hier via de link.</p>
                                                            <a href="http://www.invoorzorg.nl/docs/ivz/nieuws%20NEW/boekje-Slim-ouder-worden-in-de-zorg.pdf" class="btn btn-hints"
                                                               target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11g. Voor het verplaatsen (tillen) van gevallen cliënten?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11g.</p>
                                                <p v-html="getAnswer('11.g') + getScore('11.g')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11h. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Ook voor de transfer van een gevallen cliënt geldt de norm van 23 kilo. Afhankelijk van de toestand van de cliënt en de situatie waarin hij
                                                                        of zij verkeert, kan het noodzakelijk zijn een tillift te gebruiken. </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11h.</p>
                                                <p v-html="getAnswer('11.h') + getScore('11.h')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.h').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.h').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Maak heldere afspraken over hoe ergonomisch te handelen bij een gevallen cliënt. Kijk voor films met tips over vallen op
                                                                                GoedGebruik. Ook zijn er twee Free Learning modules over dit onderwerp: ‘Valpreventie’ en ‘Na een val’.</p>
                                                            <a href="https://www.goedgebruik.nl" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11i. Voor het verplaatsen (tillen) en de zorg voor overleden cliënten?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11i.</p>
                                                <p v-html="getAnswer('11.i') + getScore('11.i')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11j. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Voor het verplaatsen van overleden cliënten is het nodig een hulpmiddel te gebruiken.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11j.</p>
                                                <p v-html="getAnswer('11.j') + getScore('11.j')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.j').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.j').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Maak vaste afspraken over het ergonomisch verplaatsen en de zorg voor overleden cliënten. Ook om te voorkomen dat er
                                                                                discussie ontstaat in situaties waarin dat niet gewenst of minder respectvol is.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11k. Voor werkhervatting na verzuim als gevolg van rugklachten?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11k.</p>
                                                <p v-html="getAnswer('11.k') + getScore('11.k')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11l. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Werkhervatting bij verzuim als gevolg van rugklachten moet zo snel mogelijk plaatsvinden, en met de juiste maatregelen. Het beleid moet er
                                                                        op zijn gericht medewerkers zo snel mogelijk te activeren en in beweging te houden. Wanneer de medewerker echter nog niet in staat is alle
                                                                        fysiek belastende handelingen te verrichten, mag dit niet betekenen dat collega’s deze extra belasting op zich nemen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11l.</p>
                                                <p v-html="getAnswer('11.l') + getScore('11.l')"></p>

                                                <div class="hint row justify-content-center mb-5"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.l').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.l').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">De brochure 'Toch nog (rug)klachten, wat nu?' geeft praktische handreikingen over wat de organisatie kan en moet doen
                                                                                voor
                                                                                medewerkers die het werk weer gaan hervatten na een periode van verzuim als gevolg van klachten aan het
                                                                                bewegingsapparaat.</p>
                                                            <a href="https://www.aovvt.nl/fileadmin/aovvt/data/tx_publication/toch_nog_rugkl.pdf" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11m. Voor het omgaan met extreem obese cliënten?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11m.</p>
                                                <p v-html="getAnswer('11.m') + getScore('11.m')"></p>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>11n. Wordt dit altijd /meestal in praktijk gebracht?</h4>
                                                        <p class="mb-0">Cliënten worden steeds zwaarder. Zo zwaar dat sommige hulpmiddelen (hoog-laag bedden, tilliften, brancards, etc.) hier niet tegen bestand
                                                                        zijn. Dan moet het duidelijk zijn hoe gehandeld moet worden, welke hulpmiddelen wel geschikt zijn en hoe je die ter plekke kunt krijgen. Het
                                                                        is nodig dat de zorgorganisatie een nadere specificatie van het preventiebeleid fysieke belasting heeft, gericht op het ergonomisch en
                                                                        respectvol omgaan met obese tot zeer obese cliënten (> 120 kg).</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">11n.</p>
                                                <p v-html="getAnswer('11.n') + getScore('11.n')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('11.n').value < 1 && this.$store.getters['physicalStore/getQuestionById']('11.n').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Op <a href="https://www.locomotion.nl/bmi40plus" target="_blank">deze website</a> vindt u uitgebreide informatie over
                                                                                ergonomische en respectvolle zorg voor cliënten met ernstig overgewicht: films, brochures, cartoons, checklists en
                                                                                artikelen. Kijk op <a href="https://www.free-learning.nl/modules/obeseclienten/start.html" target="_blank">Free
                                                                                                                                                                                           Learning</a>
                                                                                voor een e-learning module over het omgaan met extreem zware cliënten. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>12a. Worden zorgverleners, voordat ze uitvoerend actief worden in uw organisatie, getraind in manuele verplaatsingstechnieken? </h4>
                                                        <p class="mb-0">Alle zorgverleners moeten voorbereid zijn op het werk door het volgen van een basisinstructie til- en transfertechnieken. Zoals afgesproken
                                                                        in de Arbocatalogi moet dit minimaal twee dagdelen in beslag nemen. Zorgverleners moeten deze training in hun basisopleiding hebben
                                                                        gekregen. Het is desondanks verstandig de inhoud en kwaliteit van de training en hun vaardigheid te checken.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">12a.</p>
                                                <p v-html="getAnswer('12.a') + getScore('12.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('12.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Ter ondersteuning van de training in manuele verplaatsingstechnieken staat er op <a href="https://www.goedgebruik.nl"
                                                                                                                                                                    target="_blank">GoedGebruik</a> een
                                                                                groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier,
                                                                                is
                                                                                te vinden in het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a
                                                                    href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor elke
                                                                                zorgverlener.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>12b. Worden leerlingen, voordat ze uitvoerend actief worden in uw organisatie, getraind in manuele verplaatsingstechnieken?</h4>
                                                        <p class="mb-0">Alle leerlingen moeten voorbereid zijn op het werk door het volgen van een basisinstructie til- en transfertechnieken. Zoals afgesproken in
                                                                        de Arbocatalogi moet dit minimaal twee dagdelen in beslag nemen. Leerlingen moeten deze training in hun basisopleiding krijgen, maar het is
                                                                        desondanks verstandig de inhoud en kwaliteit van de training te checken. </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">12.b</p>
                                                <p v-html="getAnswer('12.b') + getScore('12.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('12.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Ter ondersteuning van de training in manuele verplaatsingstechnieken staat er op <a href="https://www.goedgebruik.nl"
                                                                                                                                                                    target="_blank">GoedGebruik</a> een
                                                                                groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier,
                                                                                is
                                                                                te vinden in het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a
                                                                    href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor elke
                                                                                zorgverlener
                                                                                (in opleiding). </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>12c. Worden zorgverleners, voordat ze uitvoerend actief worden in uw organisatie, getraind in het werken met tilhulpmiddelen?</h4>
                                                        <p class="mb-0">Alle zorgverleners moeten voorbereid zijn op het werk door het volgen van een basisinstructie in het werken met tilhulpmiddelen (tilliften,
                                                                        glijzeilen, etc.). Zoals afgesproken in de Arbocatalogi moet dit minimaal twee dagdelen in beslag nemen. Zorgverleners moeten deze training
                                                                        in hun basisopleiding krijgen, maar het is desondanks verstandig de inhoud en kwaliteit van de training te checken.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">12.c</p>
                                                <p v-html="getAnswer('12.c') + getScore('12.c')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('12.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.c').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                                                een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                                                in
                                                                het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                                     target="_blank">De Leerstand</a>
                                                                voor
                                                                een individueel ‘op maat’ leeradvies voor elke zorgverlener.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>12d. Worden leerlingen, voordat ze uitvoerend actief worden in uw organisatie, getraind in het gebruik van tilhulpmiddelen?</h4>
                                                        <p class="mb-0">Alle leerlingen moeten voorbereid zijn op het werk door het volgen van een basisinstructie in het werken met tilhulpmiddelen (tilliften,
                                                                        glijzeilen, etc.). Zoals afgesproken in de Arbocatalogi moet dit minimaal twee dagdelen in beslag nemen. Leerlingen moeten deze training in
                                                                        hun basisopleiding krijgen, maar het is desondanks verstandig de inhoud en kwaliteit van de training te checken.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">12.d</p>
                                                <p v-html="getAnswer('12.d') + getScore('12.d')"></p>
                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('12.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                                                een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                                                in
                                                                het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                                     target="_blank">De Leerstand</a>
                                                                voor
                                                                een een individueel ‘op maat’ leeradvies voor elke zorgverlener (in opleiding).
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>12e. Worden alle zorgverleners regelmatig getraind in het uitvoeren van manuele tiltechnieken?</h4>
                                                        <p class="mb-0">Zoals afgesproken in de Arbocatalogi is er, gecombineerd met een training in het gebruik van hulpmiddelen, standaard één keer per jaar
                                                                        minimaal één dagdeel bijscholing nodig in manuele transfertechnieken voor medewerkers die zorg verlenen aan cliënten in de
                                                                        Mobiliteitsklassen B-E. Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per
                                                                        twee jaar). Een langere periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven,
                                                                        dan minder vaak een langere. In deze training kan ook aandacht worden besteed aan het voorkomen van en het omgaan met agressie en weerstand
                                                                        tijdens de basiszorg.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">12.e</p>
                                                <p v-html="getAnswer('12.e') + getScore('12.e')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('12.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.e').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                                                een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                                                in
                                                                het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                                     target="_blank">De Leerstand</a>voor
                                                                een individueel ‘op maat’ leeradvies voor elke zorgverlener.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>12f. Worden alle zorgverleners regelmatig getraind in het gebruik van tilhulpmiddelen?</h4>
                                                        <p class="mb-0">Zoals afgesproken in de Arbocatalogi is er, gecombineerd met een training in manuele transfertechnieken, standaard één keer per jaar
                                                                        minimaal één dagdeel bijscholing nodig in het gebruik van tilhulpmiddelen voor medewerkers die zorg verlenen aan cliënten in de
                                                                        Mobiliteitsklassen B-E. Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per
                                                                        twee jaar). Een langere periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven,
                                                                        dan minder vaak een lagere. In deze training kan ook aandacht worden besteed aan het voorkomen van en het omgaan met agressie en weerstand
                                                                        tijdens de basiszorg.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">12.f</p>
                                                <p v-html="getAnswer('12.f') + getScore('12.f')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('12.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('12.f').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Ter ondersteuning van de training in het werken met tilhulpmiddelen staat er op <a href="https://www.goedgebruik.nl" target="_blank">GoedGebruik</a>
                                                                een groot aantal films en pdf’s waarin de technieken worden getoond en beschreven. Dezelfde informatie, maar dan op papier, is te vinden
                                                                in
                                                                het <a href="https://www.locomotion.nl/winkeltje" target="_blank">GebruiksBoekje</a>. Kijk ook op <a href="https://www.leerstand.nl"
                                                                                                                                                                     target="_blank">De Leerstand</a>voor
                                                                een individueel ‘op maat’ leeradvies voor elke zorgverlener.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h3>13. Worden medewerkers van ondersteunende diensten die fysiek zwaar werk doen voorgelicht en getraind?</h3>
                                                        <h4>13a. Worden deze medewerkers, voordat ze uitvoerend actief worden voorgelicht, getraind in tiltechnieken en werkmethoden?</h4>
                                                        <p class="mb-0">Alle medewerkers van ondersteunende diensten die fysiek belastend werk doen moeten voorbereid zijn op het werk door het volgen van een
                                                                        basisinstructie tiltechnieken en werkmethoden. Zoals afgesproken in de Arbocatalogus moet deze training minimaal twee dagdelen in beslag
                                                                        nemen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">13a.</p>
                                                <p v-html="getAnswer('13.a') + getScore('13.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('13.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                                                de
                                                                Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                                              target="_blank">LOCOmotion
                                                                                                                                                              winkeltje</a>.
                                                                Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                                                manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                                                Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                                                medewerkers
                                                                van ondersteunende diensten.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>13b. Worden deze medewerkers, voordat ze uitvoerend actief worden, getraind in het gebruik van tilhulpmiddelen en werkmaterialen?</h4>
                                                        <p class="mb-0">Alle medewerkers van ondersteunende diensten die fysiek belastend werk doen moeten voorbereid zijn op het werk door het volgen van een
                                                                        basisinstructie in het gebruik van tilhulpmiddelen en werkmaterialen. Zoals afgesproken in de Arbocatalogus moet deze training minimaal twee
                                                                        dagdelen in beslag nemen.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">13b.</p>
                                                <p v-html="getAnswer('13.b') + getScore('13.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('13.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                                                de
                                                                Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                                              target="_blank">LOCOmotion
                                                                                                                                                              winkeltje</a>.
                                                                Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                                                manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                                                Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                                                medewerkers
                                                                van ondersteunende diensten.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>13c. Worden deze medewerkers jaarlijks voorgelicht en getraind in het uitvoeren van tiltechnieken en werkmethoden?</h4>
                                                        <p class="mb-0">Zoals afgesproken in de Arbocatalogus is er, gecombineerd met een training in het gebruik van tilhulpmiddelen en werkmaterialen, standaard
                                                                        één keer per jaar minimaal één dagdeel bijscholing nodig in tiltechnieken en werkmethoden voor medewerkers van ondersteunende diensten die
                                                                        fysiek zwaar werk doen. <br>
                                                                        Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per twee jaar). Een langere
                                                                        periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven, dan minder vaak een
                                                                        langere.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">13c.</p>
                                                <p v-html="getAnswer('13.c') + getScore('13.c')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('13.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.c').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                                                de
                                                                Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                                              target="_blank">LOCOmotion
                                                                                                                                                              winkeltje</a>.
                                                                Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                                                manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                                                Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                                                medewerkers
                                                                van ondersteunende diensten.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>13d. Worden deze medewerkers jaarlijks getraind in het gebruik van tilhulpmiddelen en werkmaterialen?</h4>
                                                        <p class="mb-0">Zoals afgesproken in de Arbocatalogus is er, gecombineerd met een training in tiltechnieken en werkmethoden, standaard één keer per jaar
                                                                        minimaal één dagdeel bijscholing nodig in het gebruik van tilhulpmiddelen en werkmaterialen voor medewerkers van ondersteunende diensten die
                                                                        fysiek zwaar werk doen.
                                                                        Het is ook mogelijk, maar minder gewenst, om eens per twee jaar een langere training te geven (minstens een dag per twee jaar). Een langere
                                                                        periode tussen de trainingen is beslist niet bedoeling. Het is effectiever om vaker een kortere training te geven, dan minder vaak een
                                                                        langere.</p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">13d.</p>
                                                <p v-html="getAnswer('13.d') + getScore('13.d')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('13.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('13.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">
                                                                Het DoeBoekje staat vol met tekeningen en praktische tips over tillen, duwen, trekken, reiken, knielen, etc. Alle informatie is conform
                                                                de
                                                                Praktijkrichtlijnen Fysieke Belasting. Meer informatie is te vinden in het <a href="https://www.locomotion.nl/winkeltje"
                                                                                                                                              target="_blank">LOCOmotion
                                                                                                                                                              winkeltje</a>.
                                                                Op <a href="https://www.free-learning.nl" target="_blank">Free Learning</a> staan gratis e-learning modules, bijvoorbeeld over het
                                                                manoeuvreren met zwaar rollend materiaal en het gezond werken achter een beeldscherm.<br>
                                                                Kijk ook op <a href="https://www.leerstand.nl" target="_blank">De Leerstand</a> voor een individueel ‘op maat’ leeradvies voor
                                                                medewerkers
                                                                van ondersteunende diensten.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h3 class="color-primary">14. Zijn er voldoende hulpmiddelen beschikbaar? </h3>
                                                        <p class="mb-0">Voldoende' wil zeggen dat er voldoende hulpmiddelen zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen werken. Hoewel de <a
                                                            href="https://www.tilthermometer.nl" target="_blank">TilThermometer</a> het gebruik van de hulpmiddelen beoordeelt (en niet de aanwezigheid) zijn de
                                                                        resultaten van een meting met de TilThermometer zeker informatief op dit punt. Als ze worden gebruikt, zijn ze er immers.
                                                                        Houd er rekening mee dat het hier alleen gaat om hulpmiddelen die nodig zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen
                                                                        werken. Andere hulpmiddelen, denk aan het opstalooprek of het draaibed, kunnen de fysieke overbelasting aanvullend reduceren. Vaak ook
                                                                        indirect, doordat de zelfredzaamheid van de cliënt wordt gefaciliteerd. </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14a.</p>
                                                <p v-html="getAnswer('14.a') + getScore('14.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over de Praktijkrichtlijnen Fysieke Belasting.</p>
                                                            <a href="https://www.free-learning.nl/modules/basisfysiekgezondwerken/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14b. Glijhulpmiddelen</h4>
                                                        <p class="mb-0">
                                                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-5.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a> die hier van
                                                            toepassing is luidt: als de cliënt Mobiliteitsklasse C, D of E heeft, dan moet een glijhulpmiddel (of vergelijkbaar hulpmiddel) in combinatie met een
                                                            elektrisch hoog-laag bed worden gebruikt.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14b.</p>
                                                <p v-html="getAnswer('14.b') + getScore('14.b')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.b').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.b').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over het werken met glijzeilen.</p>
                                                            <a href="https://www.free-learning.nl/modules/werkenmetglijzeilen/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14c. Staliften (actieve tilliften)</h4>
                                                        <p class="mb-0">
                                                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-3.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a> die hier van
                                                            toepassing is luidt: als de cliënt Mobiliteitsklasse C heeft, moet er een actieve (of sta-)lift worden gebruikt. De cliënt kan dan zelfstandig
                                                            (op)staan, heeft enige rompbalans en kan enigszins steun nemen op een of beide benen.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14c.</p>
                                                <p v-html="getAnswer('14.c') + getScore('14.c')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.c').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.c').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Staliften zijn in drie soorten: manueel over de vloer verrijdbaar, gemotoriseerd over de vloer verrijdbaar en hangend
                                                                                aan
                                                                                het plafond. Het manoeuvreren met gemotoriseerde liften en plafondliften kost nauwelijks kracht en heeft dus ergonomisch
                                                                                gezien de voorkeur. <br /> Kijk op Free Learning voor een e-learning module over het werken met de stalift en de
                                                                                passieve
                                                                                lift.</p>
                                                            <a href="https://www.free-learning.nl/modules/staliftenpassievelift/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14d. Passieve tilliften</h4>
                                                        <p class="mb-0">
                                                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-3.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a> die hier van
                                                            toepassing is luidt: als de cliënt Mobiliteitsklasse D of E heeft, moet er een passieve lift gebruikt worden. De cliënt heeft dan onvoldoende rompbalans
                                                            en kan geen steun nemen op de benen.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14d.</p>
                                                <p v-html="getAnswer('14.d') + getScore('14.d')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.d').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.d').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Passieve liften zijn in drie soorten: manueel over de vloer verrijdbaar, gemotoriseerd over de vloer verrijdbaar en
                                                                                hangend
                                                                                aan het plafond. Het manoeuvreren met gemotoriseerde liften en plafondliften kost nauwelijks kracht en heeft dus
                                                                                ergonomisch
                                                                                gezien de voorkeur. <br /> Kijk op Free Learning voor een e-learning module over het werken met de stalift en de
                                                                                passieve
                                                                                lift.</p>
                                                            <a href="https://www.free-learning.nl/modules/staliftenpassievelift/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14e. Steunkousaan- en uittrekhulpmiddelen</h4>
                                                        <p class="mb-0">
                                                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-1.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a>die hier van
                                                            toepassing is luidt: bij het aan- en uittrekken van steunkousen van drukklasse 2 of hoger moet er een speciaal hulpmiddel worden gebruikt.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14e.</p>
                                                <p v-html="getAnswer('14.e') + getScore('14.e')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.e').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.e').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over het aan- en uittrekken van steunkousen.</p>
                                                            <a href="https://www.free-learning.nl/modules/steunkousen/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14f. Hoog-laag douchestoelen</h4>
                                                        <p class="mb-0">
                                                            De Praktijkrichtlijn Fysieke Belasting die hier van toepassing is luidt: niet langer dan ongeveer een minuut aaneengesloten werken met een meer dan 30°
                                                            gebogen of gedraaide romp of nek. In de praktijk betekent dit dat bij zorg bij zittend douchen een in hoogte verstelbare douchestoel gebruikt moet
                                                            worden.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14f.</p>
                                                <p v-html="getAnswer('14.f') + getScore('14.f')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.f').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.f').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over statische belasting.</p>
                                                            <a href="https://www.free-learning.nl/modules/statische-belasting/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14g. Hoog-laag douchebrancards</h4>
                                                        <p class="mb-0">
                                                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-2.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a>die hier van
                                                            toepassing is luidt: niet langer dan ongeveer een minuut aaneengesloten werken met een meer dan 30° gebogen of gedraaide romp of nek. In de praktijk
                                                            betekent dit dat bij zorg bij liggend douchen een in hoogte verstelbare brancard moet worden gebruikt.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14g.</p>
                                                <p v-html="getAnswer('14.g') + getScore('14.g')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.g').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.g').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over statische belasting.</p>
                                                            <a href="https://www.free-learning.nl/modules/statische-belasting/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>14h. Hoog-laag baden</h4>
                                                        <p class="mb-0">
                                                            De <a href="https://www.blijfinzetbaar.nl/wp-content/uploads/2018/08/page-2.pdf" target="_blank">Praktijkrichtlijn Fysieke Belasting </a>die hier van
                                                            toepassing is luidt: niet langer dan ongeveer een minuut aaneengesloten werken met een meer dan 30° gebogen of gedraaide romp of nek. In de praktijk
                                                            betekent dit dat bij zorg bij baden een in hoogte verstelbaar bad moet worden gebruikt.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">14h.</p>
                                                <p v-html="getAnswer('14.h') + getScore('14.h')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('14.h').value < 1 && this.$store.getters['physicalStore/getQuestionById']('14.h').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Kijk op Free Learning voor een e-learning module over statische belasting.</p>
                                                            <a href="https://www.free-learning.nl/modules/statische-belasting/start.html" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>15. Wordt er minstens eenmaal per jaar bekeken of er voldoende (til)hulpmiddelen zijn?</h4>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">15.</p>
                                                <p v-html="getAnswer('15.a') + getScore('15.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('15.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('15.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Om een redelijke indicatie te hebben of er nog voldoende (til)hulpmiddelen zijn is het zinvol de TilThermometer minstens
                                                                                één
                                                                                keer per jaar in te vullen.
                                                                                Daarnaast laat de TilThermometer zien of de lichamelijke zorgzwaarte is veranderd. U kunt zo de vinger aan de pols
                                                                                houden,
                                                                                investeringen beter plannen en het beleid op het gebied van training en instructie zo nodig tijdig aanpassen. De
                                                                                inspectie
                                                                                kan vragen naar een actuele TilThermometer.</p>
                                                            <a href="https://tilthermometer.nl" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>16. Worden deze hulpmiddelen goed onderhouden?</h4>
                                                        <p class="mb-0">
                                                            Onvoldoende onderhoud kan leiden tot gevaarlijke situaties voor de cliënt (een val uit een tillift) of extra belasting voor de zorgverleners (hard duwen
                                                            omdat de wieltjes niet goed draaien). <br>
                                                            Het mag niet voorkomen dat een hulpmiddel (of bepaalde mogelijkheden van een hulpmiddel) niet gebruikt kunnen worden om reden van mechanische
                                                            mankementen, die terug te voeren zijn op onvoldoende onderhoud. Daarom is het verstandig afspraken over onderhoud te borgen in het reguliere beleid.
                                                            <br>
                                                            Voor tilliften en de bijbehorende slings geldt dat ze jaarlijks aantoonbaar moeten worden onderhouden in een - al dan niet digitaal - logboekje.
                                                        </p></div>
                                                </div>
                                                <p class="mb-0 bold">16.</p>
                                                <p v-html="getAnswer('16.a') + getScore('16.a')"></p>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>17. Wordt er minstens eenmaal per jaar bekeken of er voldoende tilhulpmiddelen en werkmaterialen zijn voor medewerkers van ondersteunende diensten?</h4>
                                                        <p class="mb-0">
                                                            Gezien de enorme diversiteit in hulpmiddelen voor medewerkers van ondersteunende diensten, is geen instrument beschikbaar waarmee dit zou kunnen worden
                                                            getoetst. De vuistregel is dat iedereen over voldoende hulpmiddelen moet beschikken om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen
                                                            werken.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">17.</p>
                                                <p v-html="getAnswer('17.a') + getScore('17.a')"></p>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>18. Worden deze hulpmiddelen en werkmaterialen goed onderhouden?</h4>
                                                        <p class="mb-0">
                                                            Het mag niet voorkomen dat een hulpmiddel (of bepaalde mogelijkheden van een hulpmiddel) niet gebruikt kunnen worden om reden van mechanische
                                                            mankementen, die terug te voeren zijn op onvoldoende onderhoud. Voor klasse 1 hulpmiddelen (zoals tilliften en tilbanden) geldt dat ze jaarlijks
                                                            aantoonbaar onderhouden moeten worden.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">18.</p>
                                                <p v-html="getAnswer('18.a') + getScore('18.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('18.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('18.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Omdat het anders snel van de prioriteitenlijst verdwijnt, is het verstandig afspraken over onderhoud te borgen in
                                                                                regulier
                                                                                beleid. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>19. Wordt bij de aanschaf van nieuw materiaal nauwgezet bekeken of er volgens de Praktijkrichtlijnen Fysieke Belasting mee gewerkt kan worden?</h4>
                                                        <p class="mb-0">
                                                            Bij de aanschaf van nieuw werkmateriaal is het nodig te checken of er met het materiaal conform de Praktijkrichtlijnen Fysieke Belasting gewerkt kan
                                                            worden.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">19.</p>
                                                <p v-html="getAnswer('19.a') + getScore('19.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('19.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('19.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Neem dit punt standaard op in het programma van eisen.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="entire-question">
                                                <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5" v-if="this.showExplanations">
                                                    <span class="color-yellow rotate heading-font bold">Toelichting</span>
                                                    <div class="p-5">
                                                        <h4>20. Zijn de ruimtes en de inrichting daarvan geschikt om gezond te kunnen werken?</h4>
                                                        <p class="mb-0">
                                                            De werkruimte en de inrichting mogen geen beperking zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen werken.
                                                        </p>
                                                    </div>
                                                </div>
                                                <p class="mb-0 bold">20.</p>
                                                <p v-html="getAnswer('20.a') + getScore('20.a')"></p>

                                                <div class="hint row justify-content-center"
                                                     v-if="this.showTips && this.$store.getters['physicalStore/getQuestionById']('20.a').value < 1 && this.$store.getters['physicalStore/getQuestionById']('20.a').value !== null">
                                                    <div class="col-md-12">
                                                        <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                                            <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                                            <p class="mb-0 sub">Via de website Zorginwoningen krijgt u afhankelijk van de Mobiliteitsklasse van de cliënt en conform de
                                                                                Praktijkrichtlijnen
                                                                                Fysieke Belasting, inzicht in hoeveel ruimte er nodig is om veilig zorg te kunnen leveren in ruimtes waar cliënten
                                                                                verblijven.</p>
                                                            <a href="https://www.zorginwoningen.nl" class="btn btn-hints" target="_blank">Link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                    <span class="accordion-title">
                                        1. Beleid algemeen
                                    </span>
                                    </div>
                                    <div id="collapse1" class="collapse show">
                                        <div class="accordion-body">
                                            <p>Deze score vormt een samenvattend oordeel over het beleid fysieke belasting. Uw scores op de overige dimensies en uiteraard
                                               de afzonderlijke items maken dit concreter en objectiever en brengen eventuele verbeterpunten gerichter in beeld.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                                    <span class="accordion-title">
                                        2. Praktijkrichtlijnen Fysieke Belasting
                                    </span>
                                    </div>
                                    <div id="collapse2" class="collapse">
                                        <div class="accordion-body">
                                            <p>De score op dit onderwerp geeft de mate weer waarin u (de onderdelen van) de Praktijkrichtlijnen uit de ARBO Catalogus op schrift heeft
                                               gezet voor uw eigen organisatie en in het beleid van de eigen organisatie heeft ingepast.</p>
                                            <p class="italic mb-0">
                                                Een effectief preventiebeleid fysieke belasting betekent in de eerste plaats voorkomen dat mensen te zwaar belast
                                                worden. De beste manier om dat te doen, is te werken met duidelijke grenzen. De grenzen voor fysieke belasting (in
                                                feite het moment waarop fysieke belasting verandert in fysieke overbelasting) moeten voor iedereen duidelijk zijn en
                                                bekrachtigd worden in het beleid van de instelling. Deze grenzen zijn vastgelegd in de Praktijkrichtlijnen. De
                                                richtlijnen geven precies aan wat nog wel en wat niet meer gezondheidkundig aanvaardbaar is. Deze richtlijnen zijn
                                                niet meer vrijblijvend, maar zijn opgenomen in de Arbocatalogus VVT, tevens het toetsingskader voor de
                                                arbeidsinspectie, zijn opgenomen in de CEN ISO TR 12296 en zijn in 2014 erkend door de V&VN. Ze worden periodiek
                                                bijgesteld op basis van de laatste stand der techniek. De meest recente versie treft u altijd aan op
                                                www.arbocatalogusvvt.nl.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                                    <span class="accordion-title">
                                        3. Organisatie Fysiek
                                    </span>
                                    </div>
                                    <div id="collapse3" class="collapse">
                                        <div class="accordion-body">
                                            <p>Hier gaat het om de mate waarin (beleids)verantwoordelijken en deskundigheid voor fysieke belasting in de eigen organisatie aanwezig
                                               zijn. Te denken valt aan een werkgroep fysieke belasting en de aanwezigheid van ErgoCoaches. Om preventiebeleid te kunnen bijsturen en de
                                               implementatie van de Praktijkrichtlijnen vorm te geven, is communicatie tussen de beleidsverantwoordelijken en uitvoerenden immers
                                               essentieel. </p>
                                            <p class="italic mb-0">Het aanstellen van ‘aandachtsvelders’ fysieke belasting voor de teams of functiegroepen kan een belangrijke stimulans
                                                                   zijn voor het beleid en de uitvoering daarvan. Deze aandachtsvelders (bijvoorbeeld ErgoCoaches) functioneren direct
                                                                   op de werkvloer. Het zijn gewone teamleden met een aanvullende specifieke deskundigheid op het gebied van fysieke
                                                                   belasting. Ze zijn in staat het beleid op een praktische manier letterlijk en figuurlijk te vertalen naar de teams en
                                                                   diensten, dat beleid daar te stimuleren en uitvoerenden te coachen. Ook kunnen ze een commissie fysieke belasting van
                                                                   informatie voorzien over hoe maatregelen door uitvoerenden worden gewaardeerd en eventuele problemen die daarbij
                                                                   optreden.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                    <span class="accordion-title">
                                        4. Protocollair werken
                                    </span>
                                    </div>
                                    <div id="collapse4" class="collapse">
                                        <div class="accordion-body">
                                            <p>Deze score geeft de mate aan waarin men protocollair werkt, dat wil zeggen afspraken vastlegt in zorgdossiers en, minstens zo belangrijk,
                                               toeziet op naleving.</p>
                                            <p class="italic"> Om gezond te kunnen werken is het nodig om bij de start van de zorgverlening (veelal de opname) een zorgvuldige
                                                               inschatting te maken van de risico’s op fysieke overbelasting die de zorg aan de cliënt oplevert. ‘Zorgvuldig’ wil zeggen
                                                               dat in ieder geval de criteria uit de Praktijkrichtlijnen worden toegepast. Op basis van die inschatting moeten er
                                                               afspraken worden gemaakt over hulpmiddelen, werkmaterialen, werkmethoden en/of de werkomgeving. Om ervoor te zorgen dat
                                                               deze manier van werken echt geïntegreerd wordt in het zorgproces is het belangrijk dat de afspraken worden vastgelegd in
                                                               het zorgdossier en dat er wordt toegezien op naleving van die afspraken. De Inspectie voor de Gezondheidszorg let op het
                                                               actueel houden van dergelijke afspraken (tilprotocollen) naar aanleiding van een aantal (bijna) ongelukken met tilliften.
                                                               Daarnaast blijkt uit onderzoek dat het gebruik van up-to-date protocollen een van de allerbelangrijkste succesfactoren is
                                                               voor preventiebeleid (Koppelaar et al., 2012). </p>
                                            <p class="italic mb-0">Ook voor de andere medewerkers is het nodig om voor alle arbeidssituaties een zorgvuldige inschatting te maken van de
                                                                   risico’s op fysieke overbelasting die de uit te voeren werkzaamheden met zich mee kunnen brengen. Op basis van de
                                                                   inschatting van de risico’s op fysieke overbelasting kunnen dan afspraken worden gemaakt over de wijze waarop de
                                                                   werkzaamheden uitgevoerd zullen gaan worden. Het gaat dan bijvoorbeeld over de wijze waarop het transport van
                                                                   maaltijdwagens plaatsvindt, hoe het magazijn is ingericht of hoe beeldschermwerk wordt uitgevoerd. Ook hier is het
                                                                   van belang dat er wordt toegezien op naleving van de afspraken.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                    <span class="accordion-title">
                                        5. Bijzondere situaties
                                    </span>
                                    </div>
                                    <div id="collapse5" class="collapse">
                                        <div class="accordion-body">
                                            <p>De score geeft de mate aan waarin er voor bijzondere situaties (agressieve, gevallen of zeer zware cliënten, of oudere of zwangere
                                               medewerkers of bij werkhervatting na rugklachten) beleid op schrift is en dit ook in praktijk gebracht wordt.</p>
                                            <p class="italic mb-0">Voor deze bijzondere situaties geldt dat de kans op fysieke overbelasting groter is, bijvoorbeeld omdat de
                                                                   medewerkers minder fysiek belastbaar zijn of omdat de specifieke situatie van de cliënt bijzondere eisen stelt. Uit
                                                                   onderzoek blijkt dat vooral de groep 55-plussers een bijzondere risicogroep vormt. Dat geldt ook voor de zorg voor de
                                                                   groter wordende groep zeer zware cliënten. Er zijn nog te weinig instellingen die hier beleid voor hebben en dan
                                                                   ‘overvallen’ worden als er een zeer zware (150 kg plus) cliënt plots in zorg komt. Dit kan leiden tot fysiek
                                                                   gevaarlijke, maar ook voor de cliënt als gênant of onplezierig ervaren situaties.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                                    <span class="accordion-title">
                                        6. Training en instructie
                                    </span>
                                    </div>
                                    <div id="collapse6" class="collapse">
                                        <div class="accordion-body">
                                            <p>De score geeft de mate aan waarin men regelmatig training geeft aan de verschillende functiegroepen.</p>
                                            <p class="italic mb-0">Het gaat hierbij zowel om training in werkmethoden en (til)technieken als om training in het omgaan met hulpmiddelen
                                                                   en werkmaterialen. Voor zorgverleners is het van belang dat zij goed getraind worden in het gebruik van til- en
                                                                   transferhulpmiddelen. De Inspectie voor de Gezondheidszorg en Jeugd (IGJ) let vooral op voldoende en op aantoonbaar
                                                                   recente scholing in het gebruik van tilliften. De training moet regelmatig herhaald en aangepast worden als er
                                                                   bijvoorbeeld nieuwe hulpmiddelen zijn. Maar in principe geldt de noodzaak van training natuurlijk voor alle
                                                                   hulpmiddelen. In de Arbo catalogi wordt een nadere toelichting gegeven en zijn ook enkele tips opgenomen om een goede
                                                                   training te kiezen. Daarnaast bevatten de website www.goedgebruik.nl en de gratis Free Learning modules op
                                                                   www.free-learning.nl veel informatie, filmpjes en instructiemateriaal over veilige en goede werktechnieken. Voor
                                                                   medewerkers van ondersteunende diensten is er door sociale partners in de VVT een speciaal ‘DoeBoekje’ uitgegeven
                                                                   waarin voor elk van de bronnen van fysieke belasting tips en uitleg wordt gegeven. Voor de kraamzorg is er
                                                                   filmmateriaal beschikbaar dat ook in opdracht van A+O VVT is gemaakt.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                                    <span class="accordion-title">
                                        7. Blootstelling
                                    </span>
                                    </div>
                                    <div id="collapse7" class="collapse">
                                        <div class="accordion-body">
                                            <p>De blootstelling geeft de mate aan waarin men bij de verpleging en verzorging en het verdere werk in zorgorganisaties daadwerkelijk
                                               volgens de Praktijkrichtlijnen werkt. Hier krijgt u alleen een globale indicatie. Wilt u dat precies weten, vul dan de TilThermometer in
                                               via www.tilthermometer.nl. </p>
                                            <p class="italic mb-0">Om een goed preventiebeleid fysieke belasting te kunnen voeren, moet duidelijk zijn of zorgverleners aan te veel
                                                                   fysieke belasting worden blootgesteld en zo ja, aan welk soort fysieke belasting. Gaat het om tillen? Gaat het om
                                                                   handelingen waarbij in moeilijke houdingen gewerkt wordt, zoals wondverzorging? Hoe vaak gebeurt dat en hoe wordt er
                                                                   dan wondverzorging gegeven? Zijn er teams waar bepaalde problemen veel voorkomen? Gaat de zelfredzaamheid van de
                                                                   groep cliënten achteruit? Op basis van dit soort vragen kan bijvoorbeeld worden vastgesteld dat bepaalde hulpmiddelen
                                                                   nog ontbreken of dat er aanvullende maatregelen nodig zijn. Door de TilThermometer in te vullen, krijgt u precies
                                                                   inzicht in dit soort gegevens. </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                                    <span class="accordion-title">
                                        8. Onderhoud
                                    </span>
                                    </div>
                                    <div id="collapse8" class="collapse">
                                        <div class="accordion-body">
                                            <p>Hierbij gaat het om de mate waarin men afspraken heeft en naleeft op het gebied van onderhoud van hulpmiddelen (tilliften, hoog-laag
                                               bedden, douchebrancards e.d.) en werkmaterialen.</p>
                                            <p class="italic mb-0">Onvoldoende onderhoud kan gevaarlijke situaties opleveren voor de cliënt (bijvoorbeeld een val uit een tillift) of
                                                                   extra belasting voor uitvoerenden (bijvoorbeeld te hard moeten duwen omdat de wieltjes niet goed draaien). Het mag in
                                                                   principe niet voorkomen dat een hulpmiddel (of bepaalde functies ervan) niet gebruikt kan worden door technische
                                                                   mankementen als gevolg van onvoldoende onderhoud. Om die reden is preventief onderhoud cruciaal. Voor medische
                                                                   hulpmiddelen zoals tilliften (medische hulpmiddelen klasse 1) is dit onderhoud bovendien aan strikte eisen gebonden.
                                                                   Uitgebreide informatie daarover vindt u in de Arbocatalogus en er is ook een speciale brochure beschikbaar.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mb-3 ">
                                    <div class="accordion-header collapsed" data-toggle="collapse" data-target="#collapse9" aria-expanded="false" aria-controls="collapse9">
                                    <span class="accordion-title">
                                        9. Ergonomie
                                    </span>
                                    </div>
                                    <div id="collapse9" class="collapse">
                                        <div class="accordion-body">
                                            <p> De score geeft de mate aan waarin men beleid heeft om met ruimtelijke knelpunten om te gaan en dus of de ruimtes waarin men werkt
                                                ergonomisch daarvoor geschikt zijn en of daar bij bouw en verbouw rekening mee wordt gehouden.</p>
                                            <p class="italic">Ten eerste gaat het daarbij om de vraag of de ruimte en inrichting geschikt zijn om er goed te kunnen werken. Een tweede
                                                              punt van belang is of er bij bouw of verbouw rekening wordt gehouden met de fysieke arbeidsomstandigheden. Het is
                                                              noodzakelijk om bij dit soort ingrijpende veranderingen de Praktijkrichtlijnen tijdig te betrekken. Dat maakt het
                                                              eenvoudiger en veelal minder kostbaar om er aan te voldoen en het voorkomt dat men voor voldongen feiten staat.</p>
                                            <p class="italic mb-0">Behalve de informatie in de Arbocatalogi vindt u heel praktische en toch ook voor architecten geschikte (tekeningen
                                                                   die meteen te gebruiken zijn door een architect) informatie op www.zorginwoningen.nl. Deze website houdt integraal
                                                                   rekening met de noodzaak om zo lang mogelijk zelfredzaam te blijven voor cliënten en toch ook in ruimtes verantwoord
                                                                   te kunnen werken door zorgverleners en/of mantelzorgers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="btn-container text-center">
                            <a href="#" @click="exportData" class="btn btn-grad mt-3">Download als PDF<i class="fas fa-arrow-down"></i></a>
                        </div>
                    </div>
                </div>
            </section>
            <Progress />
        </div>
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ResultChart from "../includes/ResultChart";
import {jsPDF} from 'jspdf';
import domtoimage from "dom-to-image-more/src/dom-to-image-more";

export default {
    name:       'Results',
    components: {
        ResultChart,
        Progress
    },
    computed:   {
        name() {
            return this.$store.getters['physicalStore/getName']();
        },
        organizationName() {
            return this.$store.getters['physicalStore/getOrganizationName']();
        },
        date() {
            let date = new Date();

            return ("0" + date.getDate()).slice(-2) + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + date.getFullYear();
        }
    },
    data() {
        return {
            resultsChartKey:  0,
            exporting:        false,
            showTips:         true,
            showExplanations: true
        }
    },
    methods: {
        forceRerender() {
            this.resultsChartKey += 1;
        },
        getAnswer(id) {
            const question      = this.$store.getters['physicalStore/getQuestionById'](id);
            const questionValue = question.value === null ? '<i>Niet ingevuld</i>' : question.value;

            if(questionValue === 1) {
                return '<span class="color-green">' + ((question.textValue !== null) ? question.textValue : 'Ja') + '</span>';
            }

            if(questionValue === 0) {
                return '<span class="color-red">' + ((question.textValue !== null) ? question.textValue : 'Nee') + '</span>';
            }

            if(questionValue === 0.5) {
                return '<span class="color-primary">' + ((question.textValue !== null) ? question.textValue : 'Deels') + '</span>';
            }

            return questionValue;
        },
        getScore(id) {
            const question      = this.$store.getters['physicalStore/getQuestionById'](id);
            const questionValue = question.value === null ? '<i>Niet ingevuld</i>' : question.value;

            if(questionValue === 1) {
                return '<span class="ml-3">Score: <span class="color-green">' + questionValue + '</span></span>';
            }

            if(questionValue === 0) {
                return '<span class="ml-3">Score: <span class="color-red">' + questionValue + '</span></span>';
            }

            if(questionValue === 0.5) {
                return '<span class="ml-3">Score: <span class="color-primary">' + questionValue + '</span></span>';
            }

            return questionValue;
        },
        exportData(e) {
            e.preventDefault();

            document.querySelectorAll('.collapse').forEach(element => {
                element.classList.add('show');
            });
            document.getElementById('overlay-export').style.opacity = 1;
            document.querySelector('.progress').style.display       = 'none';
            document.querySelector('.first-section').classList.remove('pt-12');
            document.querySelector('.first-section').classList.add('pt-3');

            const exportable = document.getElementById('exportable');

            const headings = exportable.querySelectorAll('h1, h2, h3, h4');
            headings.forEach((heading) => {
                heading.style.fontSize     = '15px';
                heading.style.marginBottom = '5px';
            });
            const explanationCards = exportable.querySelectorAll('.card-explanation .p-5');
            explanationCards.forEach((card) => {
                card.classList.replace('p-5', 'p-1');
            });

            e.currentTarget.style.display = 'none';
            exportable.style.width        = '1200px';

            const targetElement = event.currentTarget;

            this.exporting = true;
            const vm       = this;

            domtoimage.toPng(exportable).then((imgData) => {
                const img = new Image();
                img.src   = imgData;

                img.onload = () => {
                    const imgWidth   = 210;
                    const pageHeight = 295;
                    const imgHeight  = parseInt(img.height) * parseInt(imgWidth) / parseInt(img.width);
                    let heightLeft   = imgHeight;

                    const doc    = new jsPDF('p', 'mm');
                    let position = 0;

                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight, null, 'MEDIUM');
                    heightLeft -= pageHeight;

                    while(heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                    }

                    doc.save('uw-resultaten.pdf', { returnPromise: true }).then(() => {
                        targetElement.style.removeProperty('display');
                        exportable.style.removeProperty('width');
                        document.querySelector('.progress').style.removeProperty('display');
                        document.getElementById('overlay-export').style.removeProperty('opacity');
                        document.querySelector('.first-section').classList.add('pt-12');
                        document.querySelector('.first-section').classList.remove('pt-3');

                        headings.forEach((heading) => {
                            heading.style.removeProperty('font-size');
                            heading.style.removeProperty('margin-bottom');
                        });

                        explanationCards.forEach((card) => {
                            card.classList.replace('p-1', 'p-5');
                        });

                        vm.exporting = false;
                    });
                };
            });
        },
    },
    mounted() {
        this.forceRerender();
    },
    activated() {
        this.forceRerender();
    }
}
</script>
