<script>
import {Radar} from 'vue-chartjs';

export default {
    extends: Radar,
    methods: {
        getAverageScore(questions) {
            return questions.map((question) => {
                let q = this.$store.getters['psychosocialStore/getQuestionById'](question);
                return q.value !== null ? q.value : 0;
            }).reduce((a, b) => a + b, 0) / questions.length;
        }
    },
    mounted() {
        this.renderChart({
                labels:   ['1. Uw huidige situatie', '2. Uw algemene PSA-beleid', '3. Het veiligheidsklimaat', '4. Psychosociale arbeidsbelasting', '5. Training en instructie', '6. Overgang', '7. Ouder worden', '8. Werkruimtes en veiligheid', '9. Begroting'],
                datasets: [{
                    label:           'Uw score',
                    borderColor:     'rgb(237, 0, 41)',
                    backgroundColor: 'rgba(237, 0, 41, 0.3)',
                    data:            [
                        this.getAverageScore(['1.a', '1.b', '1.c']),
                        this.getAverageScore(['2.a.a', '2.a.b', '2.b', '2.c', '2.d', '2.e', '2.f', '2.f']),
                        this.getAverageScore(['3.a', '3.b', '3.c', '3.d', '3.e']) / 10,
                        this.getAverageScore(['4.a.a', '4.a.b', '4.b.a', '4.b.b', '4.c.a', '4.c.b', '4.c.c', '4.c.d', '4.d.a', '4.d.b', '4.e.a', '4.e.b']),
                        this.getAverageScore(['5.a']),
                        this.getAverageScore(['6.a', '6.b']),
                        this.getAverageScore(['7.a', '7.b']),
                        this.getAverageScore(['8.a']),
                        this.getAverageScore(['9.a'])
                    ]
                }]
            },
            {}
        )
    }
}
</script>
