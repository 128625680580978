<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 5.10 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">6. Overgang</h3>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    Hoewel de overgang geen ziekte is, maar een natuurlijke fase in het leven van een vrouw, hebben veel vrouwen tussen de 45-55 jaar er in meer of mindere mate last
                                    van. Soms zo erg dat het leidt tot verzuim of minder plezier in het werk. In de zorg is rond de 65% van de vrouwen in de overgang. De overgang kan gepaard gaan met
                                    de klassieke verschijnselen zoals ’s nachts transpireren, opvliegers, stemmingswisselingen en vergeetachtigheid. Drie van de vier vrouwen hebben er last van en één
                                    van de vier heeft ernstige klachten. Soms zijn de klachten zelfs vergelijkbaar met burnout klachten.
                                </p>
                                <p>
                                    Desondanks lijkt er nog steeds een taboe op te rusten. Vrouwen maken hun problemen rondom de overgang nauwelijks bespreekbaar. Bijvoorbeeld omdat ze bang zijn dat
                                    er in het team lacherig over wordt gedaan. Of omdat ze het zelf als een teken van verval zien. Of omdat ze een drempel ervaren het te melden aan een leidinggevende,
                                    die bijvoorbeeld man en jonger is. Toch is die openheid nodig om elkaar te kunnen ondersteunen, verzuim te voorkomen en met plezier te kunnen blijven werken.
                                </p>
                                <p class="mb-0">
                                    Bijzonder is dat er geen specifieke richtlijnen zijn vastgelegd in de Arbo-wet of het inspectiebeleid. Ook in veel Arbocatalogi staat nauwelijks of helemaal geen
                                    informatie over de menopauze en hoe daar in een zorgorganisatie mee om te gaan. Dat neemt niet weg dat het belangrijk blijft om beleid op dit punt uit te voeren.
                                </p>
                            </div>
                        </div>
                        <h4>Is er in uw organisatie beleid op schrift aanwezig ten aanzien van het ondersteunen van werknemers die last ondervinden van de overgang / menopauze?</h4>
                        <ChoiceField id="6.a" />
                        <h4>Wordt dit altijd / meestal in praktijk gebracht?</h4>
                        <ChoiceField id="6.b" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="(this.$store.getters['psychosocialStore/getQuestionById']('6.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('6.a').value !== null) ||
                                        (this.$store.getters['psychosocialStore/getQuestionById']('6.b').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('6.b').value !== null)">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <div class="sub text-justify">
                                            <ol>
                                                <li>
                                                    Gezond & Zeker heeft het BlijfGezond boekje uitgebracht waarin expliciet aandacht is voor de overgang van zorgmedewerkers. Het kan daar besteld
                                                    worden (<a href="https://gezondenzeker.nl" target="_blank">gezondenzeker.nl</a>).
                                                </li>
                                                <li>
                                                    IZZ heeft een dossier opgebouwd over de overgang in de zorg en onder meer een stappenplan ontwikkeld. Globaal gaat het om de volgende
                                                    stappen. Meer specifieke informatie vindt u op de site van IZZ (<a href="https://izz.nl/overgang" target="_blank">www.izz.nl/overgang</a>)
                                                    <ul>
                                                        <li>
                                                            <strong>Stap 1</strong><br>
                                                            Weet wat er speelt (hoeveel vrouwen in de overgangsleeftijd werken er in jouw zorgorganisatie? En wat is de invloed van de overgang op jouw
                                                            zorgorganisatie?).
                                                        </li>
                                                        <li>
                                                            <strong>Stap 2</strong><br>
                                                            Maak het belangrijk (haal het taboe eraf, geef aandacht aan de overgang, zet het op de agenda).
                                                        </li>
                                                        <li>
                                                            <strong>Stap 3</strong><br>
                                                            Kom in actie (herken en erken de overgang).
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    Er is een overzichtsstudie verschenen naar de invloed van overgang op het werk. <a href="/uploads/Literatuurverkenning naar overgang.pdf"
                                                                                                                                       target="_blank">Deze kunt u
                                                                                                                                                       hier downloaden</a>.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-7'}" class="btn btn-grad mt-3">7. Ouder worden en PSA-beleid <i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep6',
    components: { ChoiceField, Progress }
}
</script>
