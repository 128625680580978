<template>
    <div>
        <section class="p-0">
            <div class="row">
                <div class="col-md-12 image-bg page-title">
                    <div class="background-image overlay-gradient-left" style="background-image:url(/img/about-us.jpg);"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1 class="large" data-aos="fade-up" data-aos-delay="100">Over de BeleidsSpiegel</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-10">
                        <h2>Is het beleid psychosociale arbeidsbelasting in uw organisatie op orde?</h2>
                        <p>
                            De BeleidsSpiegel PSA is een checklist die u helpt om het beleid psychosociale arbeidsbelasting in uw organisatie in kaart te brengen en te 'spiegelen' aan de landelijke
                            afspraken.
                            Het begrip psychosociale arbeidsbelasting (PSA) is in artikel 1 van de Arbowet gedefinieerd. Onder PSA vallen alle factoren die op het werk stress kunnen veroorzaken. Te
                            denken valt aan agressie, geweld, seksuele intimidatie, discriminatie, pesten en werkdruk. Maar liefst een derde van het langdurend verzuim in de zorg heeft geheel of deels
                            een psychische of psychosociale oorzaak: vaak gaat het dan om een te hoge psychosociale arbeidsbelasting (PSA).
                        </p>
                        <p>
                            In het ideale geval kunt u alle vragen van deze checklist met ‘ja’ beantwoorden. Zo niet, dan geeft deze online BeleidsSpiegel u tips over hoe u de situatie kunt
                            verbeteren. Het kan zijn dat er dan verwezen wordt naar de Arbo-catalogus van uw branche, maar ook verwijzingen naar andere handige plaatsen en tips, zoals naar de website
                            van Gezond & Zeker zijn mogelijk.
                        </p>
                        <p>
                            Aan het eind van de checklist krijgt u een totaaloverzicht van uw scores te zien en kunt u een pdf laten maken van uw gegevens die u kunt opslaan. Dit document kunt u
                            intern gebruiken voor bijv. een beleidsplan en natuurlijk voor later om te zien of en zo ja, op welke punten er vooruitgang is geboekt. Let op: om privacy redenen slaat
                            deze website uw gegevens niet op: dus zorg dat u de pdf ook daadwerkelijk opslaat.
                        </p>
                        <div class="btn-container">
                            <router-link :to="{name: 'psychosocial-step-0'}" class="btn btn-grad">Direct invullen</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Partner />
    </div>
</template>

<script>
import Partner from "../../defaults/Partners";

export default {
    name:       'PsychosocialAbout',
    components: { Partner }
}
</script>
