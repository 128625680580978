<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <!-- Question 5.10 -->
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">8. Werkruimtes en veiligheid</h3>
                        <div class="bg-white rounded shadow-sm card-explanation d-flex align-items-center text-justify mb-5">
                            <span class="color-yellow rotate heading-font bold">Toelichting</span>
                            <div class="p-5">
                                <p>
                                    De omgeving en ruimte waar gewerkt wordt is sterk van invloed op de beleving van het werk, stress en ontspanning. Deze invloed wordt nog wel eens onderschat, maar
                                    biedt veel mogelijkheden.
                                </p>
                                <p>
                                    Vanuit de Arbo-wet zijn er geen concrete eisen op dit punt, maar er zijn veel mogelijkheden om werkruimtes in te richten om deze optimaal te laten zijn.
                                </p>
                                <p class="mb-0">
                                    Behalve de meer subtiele zaken zoals kleuren, planten, licht, frisse lucht kan het ook heel concreet gaan om veiligheid en het voorkomen van agressie en geweld. Als
                                    voorbeeld geldt dat verlichting het gevoel van sociale veiligheid en werkdruk kan beïnvloeden. Maar ook de manier waarop bijv. een spoed eisende hulp wordt
                                    ingericht is
                                    zeer bepalend voor het gevoel van veiligheid en werkdruk van zorgverleners die daar werken.
                                </p>
                            </div>
                        </div>
                        <h4>Zijn de ruimtes en de inrichting daarvan geschikt om gezond en veilig te kunnen werken?</h4>
                        <ChoiceField id="8.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]" />
                        <transition name="fade-in-up">
                            <div class="hint row justify-content-center mb-5"
                                 v-if="this.$store.getters['psychosocialStore/getQuestionById']('8.a').value < 1 && this.$store.getters['psychosocialStore/getQuestionById']('8.a').value !== null">
                                <div class="col-md-8">
                                    <div class="p-4 rounded bg-white box-shadow d-flex align-items-center">
                                        <span class="color-yellow rotate heading-font bold mr-3">Tip</span>
                                        <div class="sub text-justify">
                                            <ol>
                                                <li>
                                                    Vanuit de internationaal geaccepteerde CPTED (Crime Prevention Through Environmental Design) benadering wordt verlichting vooral gezien als
                                                    technisch middel om meer mogelijkheden te schepen voor ‘natural surveillance’. <a
                                                    href="https://www.omgevingspsycholoog.nl/sociale-veiligheid-verbeteren-met-verlichting/" target="_blank">Een artikel door Randy Bloeme</a> gaat daar
                                                    dieper op in.
                                                </li>
                                                <li>
                                                    <a href="https://www.bouwenaandezorg.eu/onderwerp/woonzorg-verpleeghuizen/" target="_blank">Op deze website</a>
                                                    kunt u veel inspiratie opdoen over inrichting en bouwen van ruimtes in de zorg en de invloed op de beleving door zorgverleners en cliënten;
                                                    kleurgebruik, materiaal gebruik en verlichting.
                                                </li>
                                                <li>
                                                    Het UKON (Universitair Kennisnetwerk Ouderen Nijwegen ontwikkelde een praktische methode voor verpleeghuizen <a href="https://www.zorgvoorbeter.nl/dementie/persoonsgerichte-zorg/leefomgeving?utm_medium=email" target="_blank">om te werken aan een veilig en gezond thuis</a>.
                                                </li>
                                                <li>
                                                    <a href="https://www.free-learning.nl/modules/samen-werken-aan-een-thuis/start.html" target="_blank">De Free Learning ‘Samen werken aan een thuis’</a> gaat over hoe je de leefomgeving van bewoners met dementie gezond en veilig kunt maken.
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'psychosocial-step-9'}" class="btn btn-grad mt-3">9. Begroting <i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'PsychosocialStep8',
    components: { ChoiceField, Progress }
}
</script>
