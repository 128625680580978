<template>
    <div>
        <section class="p-0">
            <div class="row">
                <div class="col-md-12 image-bg page-title">
                    <div class="background-image overlay-gradient-left" style="background-image:url(/img/about-us.jpg);"></div>
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h1 class="large" data-aos="fade-up" data-aos-delay="100">Over de BeleidsSpiegel</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row">
                    <div class="col-md-10">
                        <h2>Is het beleid fysieke belasting in uw organisatie op orde?</h2>
                        <p>De BeleidsSpiegel is een checklist die u helpt om het beleid fysieke belasting in uw organisatie in kaart te brengen en te 'spiegelen' aan de landelijke
                           afspraken zoals ze zijn vastgelegd in de arbocatalogus van uw branche. In het ideale geval kunt u op alle vragen ‘ja’ antwoorden. Zo niet, dan geeft deze
                           online BeleidsSpiegel u tips over hoe u de situatie kunt verbeteren. Aan het eind kun u uw resultaten vergelijken met landelijke referentiescores en kunt u een pdf bekijken
                           met uw scores en tips.</p>
                        <div class="btn-container">
                            <router-link :to="{name: 'step-0'}" class="btn btn-grad">Direct invullen</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Partner />
    </div>
</template>

<script>
import Partner from "../../defaults/Partners";

export default {
    name:       'About',
    components: { Partner }
}
</script>
