<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <div class="row justify-content-center" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <!-- Question 9.20 -->
                        <h4>20. Zijn de ruimtes en de inrichting daarvan geschikt om gezond te kunnen werken?</h4>
                        <p class="mb-5">
                            De werkruimte en de inrichting mogen geen beperking zijn om volgens de Praktijkrichtlijnen Fysieke Belasting te kunnen werken.
                        </p>
                        <ChoiceField id="20.a" :options="[{
                                value: 1,
                                label: 'Ja',
                                class: 'true'
                            }, {
                                value: 0.5,
                                label: 'Deels',
                                class: 'neutral'
                            }, {
                                value: 0,
                                label: 'Nee',
                                class: 'false'
                            }]"/>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="btn-container text-center">
                        <router-link :to="{name: 'step-results'}" class="btn btn-grad mt-3">Resultaten<i class="fas fa-arrow-right"></i></router-link>
                    </div>
                </div>
            </div>
        </section>
        <Progress/>
    </div>
</template>
<script>
import Progress from "../includes/Progress";
import ChoiceField from "../includes/ChoiceField";

export default {
    name:       'Step9',
    components: {ChoiceField, Progress}
}
</script>