<template>
    <div id="app">
        <Navigation :key="$route.fullPath" />
        <Header :key="$route.fullPath + '-header'" />
        <transition name="fade" mode="out-in">
            <keep-alive>
                <router-view name="main"></router-view>
            </keep-alive>
        </transition>
        <Footer />
    </div>
</template>

<script>
import Navigation from "./components/defaults/Navigation";
import Footer from "./components/defaults/Footer";

export default {
    name:       'App',
    components: {
        Footer,
        Navigation
    }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .3s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}

.fade-in-up-enter-active {
    transition: all .3s ease;
}

.fade-in-up-leave-active {
    transition: all .3s;
}

.fade-in-up-enter, .fade-in-up-leave-to {
    transform: translateY(100px);
    opacity: 0;
}
</style>
