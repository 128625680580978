<template>
    <div>
        <section class="bg-secondary pt-12 bg-circles-light">
            <div class="container">
                <div class="row" data-aos="fade-up">
                    <div class="col-md-12 text-center mb-3">
                        <h3 class="color-primary">Door wie, voor wie?</h3>
                        <p>Vul onderstaande gegevens in.</p>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <form>
                            <div class="form-row">
                                <div class="form-group col-md-6">
                                    <label for="0.1">Door</label>
                                    <input type="text" v-model="fullName" class="form-control" id="0.1" placeholder="Uw volledige naam">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="0.2">Voor</label>
                                    <input type="text" v-model="organizationName" class="form-control" id="0.2" placeholder="Naam organisatie">
                                </div>
                            </div>
                        </form>
                        <div class="btn-container text-center">
                            <router-link :to="{name: 'psychosocial-step-1'}" class="btn btn-grad mt-3">1. Uw huidige situatie <i class="fas fa-arrow-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="row mt-5" data-aos="fade-down">
                    <div class="col-md-12 text-center">
                        <h4>Goed om te weten</h4>
                        <p>De door u ingevulde gegevens worden niet bewaard. Wanneer de browser wordt afgesloten worden de gegevens gewist.</p>
                        <p>Wanneer u alle stappen hebt doorlopen kunt u aan het einde een overzicht met resultaten opslaan in PDF.</p>
                    </div>
                </div>
            </div>
        </section>
        <Progress />
    </div>
</template>

<script>
import Progress from "../includes/Progress";

export default {
    name:       'PsychosocialStep0',
    components: { Progress },
    computed:   {
        fullName:         {
            get() {
                return this.$store.state.psychosocialStore.fullName;
            },
            set(value) {
                this.$store.commit('psychosocialStore/setFullName', value);
            }
        },
        organizationName: {
            get() {
                return this.$store.state.psychosocialStore.organizationName;
            },
            set(value) {
                this.$store.commit('psychosocialStore/setOrganizationName', value);
            }
        }
    }
}
</script>
